import { useState, useEffect, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import axios from "axios";
import "../../src/vendorregister.css";
import { useUser } from "../contexts/UserProvider";
import { getCookie, removeCookie } from "../config/coockies";
import Header from "../components/header";
import { marketplaceapiurl } from "../config/config";
import { awsKeyId, awsSecretKey } from "../config/config";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { FaTimes } from "react-icons/fa";
const aws = require("aws-sdk");

const VendorRegisterforbusiness = () => {
  const { userData } = useUser();
  // console.log("ddd", window.location.href);
  const token = getCookie("savage_token");
  const previousSellerId = getCookie("seller_id");
  const isSeller = getCookie("is_seller");
  const [isgstValid, setisgstValid] = useState(true);
  const [ispanValid, setispanValid] = useState(true);
  const [isPanPresent, setispanPresent] = useState(false);
  const [needpay, setneedpay] = useState(false);
  const [sellerId, setSellerId] = useState("");
  const [loading, setLoading] = useState({
    image1: false,
    image2: false,
    button: false,
  });
  const rawData = {
    name: userData.name,
    email: userData.email,
    company_name: "",
    contact_no: "+1" + userData.phone,
    personal_address: "",
    profile_image: "",
    seller_id: 0,
    role_id: 2,
    status: 1,
    area_code: "",
    city: "",
    state: "",
    street: "",
    bank_account: "",
    beneficiary_name: "",
    ifsc_code: "",
    fulfillment_option: "",
    shipping_method: "",
    description: "",
    terms: "",
    commission: 1,
    business_entity_type: "",
    tax: "",
    taxnumber: "",
    cardnumber: "",
    varifydoc: "",
    varifydocimage: "",
    sellerType: "",
    pickup: "",
    dropoff: "",
    meetpublicplace: "",
    pickdropmeet: 1,
    paymentstatus: 0,
    paid: false,
    password:userData.password,
  };
  const rawErrorData = {
    name: false,
    email: false,
    company_name: false,
    contact_no: false,
    personal_address: false,
    profile_image: false,
    area_code: false,
    city: false,
    state: false,
    street: false,
    bank_account: false,
    beneficiary_name: false,
    ifsc_code: false,
    fulfillment_option: false,
    shipping_method: false,
    description: false,
    terms: false,
    commission: false,
    business_entity_type: false,
    tax: false,
    taxnumber: false,
    cardnumber: false,
    varifydoc: false,
    varifydocimage: false,
    sellerType: false,
    pickup: false,
    dropoff: false,
    meetpublicplace: false,
    pickdropmeet: false,
  };
  const [sellerData, setSellerData] = useState(rawData);
  const [sellerDataError, setSellerDataError] = useState(rawErrorData);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const inputHandler = (e) => {
    if (e.target.name === "contact_no") {
      if (e.target.value === "+1" || e.target.value === "") {
        setSellerData({ ...sellerData, [e.target.name]: "+1" });
      } else if (e.target.value.length === 1 && !isNaN(e.target.value)) {
        setSellerData({
          ...sellerData,
          [e.target.name]: "+1" + e.target.value,
        });
      } else if (e.target.value.length > 1 && !isNaN(e.target.value)) {
        setSellerData({ ...sellerData, [e.target.name]: e.target.value });
      }
    } else {
      setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }
  };
  const errorHandler = (e) => {
    console.log(e.target.name);

    if (e.target.value) {
      setSellerDataError({ ...sellerDataError, [e.target.name]: "valid" });
    } else {
      setSellerDataError({ ...sellerDataError, [e.target.name]: true });
    }
  };
  // console.log("isGSTValid--->", isgstValid)
  const newUserCreatehandler = async () => {
    setLoading({ ...loading, button: true });
    console.log("sellerData => ", sellerData);
    // console.log("sellerDataError => ", sellerDataError);
    const {
      name,
      email,
      company_name,
      contact_no,
      personal_address,
      profile_image,
      seller_id,
      role_id,
      area_code,
      city,
      state,
      street,
      bank_account,
      beneficiary_name,
      ifsc_code,
      fulfillment_option,
      shipping_method,
      description,
      terms,
      commission,
      business_entity_type,
      tax,
      taxnumber,
      cardnumber,
      varifydoc,
      varifydocimage,
      pickup,
      dropoff,
      meetpublicplace,
      pickdropmeet,
    } = sellerData;
    if (
      name &&
      email &&
      company_name &&
      contact_no &&
      area_code &&
      city &&
      state &&
      personal_address &&
      bank_account &&
      beneficiary_name &&
      ifsc_code &&
      fulfillment_option &&
      description &&
      profile_image &&
      terms &&
      business_entity_type &&
      tax &&
      taxnumber &&
      cardnumber &&
      varifydoc &&
      varifydocimage &&
      seller_id === 0 &&
      role_id
    ) {
      const { data } = await axios({
        url: `${marketplaceapiurl}/seller/vendorregister`,
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("us_st_d")}`,
        },
        data: { ...sellerData, sellerType: "business", pickdropmeet: 1 },
      });
      //const { status } = data;
      console.log("asyy", data.status);
      console.log("data", data);

      if (data.status === 200) {
        setneedpay(true);
        setSellerId(data?.data?.seller_id);
        setLoading({ ...loading, button: false });
        // window.open("https://multivendor.savageseller.com/", "_blank");
        // setTimeout(() => {
        //   window.location.href = "/profile"; // Replace '/profile' with the actual path you want to redirect to
        // }, 5000);
        return true;
      }
      if (data.status === 301) {
        toast.warning("Already registered !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("403 error !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
    } else {
      toast.error("Please fill all the fields !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const updateUserHandler = async () => {};
  const ImageHandler = async (e) => {
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
      });
      const params = {
        Bucket: "savageseller",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let { Location } = await s3.upload(params).promise();
      console.log(Location);
      return Location;
    } catch (exception) {
      console.log(exception);
      return false;
    }
  };

  const setImage = async (e) => {
    setIsImageUploading(true); // Set uploading status to true
    const image = await ImageHandler(e);
    console.log("Image----", image);
    setSellerData({ ...sellerData, [e.target.name]: image });
    setIsImageUploading(false);
  };

  const changePayments = useCallback(
    (value) => {
      setneedpay(value);
    },
    [setneedpay]
  );

  useEffect(() => {
    if (
      previousSellerId !== undefined &&
      previousSellerId !== null &&
      previousSellerId !== ""
    ) {
      setneedpay(true);
      setSellerId(parseInt(previousSellerId));
    } else if (isSeller === "true") {
      setneedpay(false);
      window.location.href = "/profile";
    } else {
      setneedpay(false);
    }
  }, [previousSellerId, isSeller]);
  return (
    <>
      <Header />

      <div className="m-0 w-100 mt-1">
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h6
                className="m-0 font-weight-bold text-primary"
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                Register yourself as a Business Vendor
              </h6>
            </div>
            <div className="card-body">
              <div className="grid md:grid-cols-3 gap-1">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Full Legel Name
                      <span className="text-danger"> * </span>
                      {sellerDataError?.name !== "valid" &&
                      sellerDataError?.name !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      value={sellerData.name}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="genericlabel">
                    {" "}
                    Business entity type <span className="text-danger">*</span>
                    {sellerDataError?.business_entity_type !== "valid" &&
                    sellerDataError?.business_entity_type !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <select
                      name="business_entity_type"
                      id=""
                      className="form-control"
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      value={sellerData?.business_entity_type}
                    >
                      <option value="" readonly>
                        Select Business Entity
                      </option>
                      <option value="partnership">Partnership</option>
                      <option value="corporation">Corporation</option>
                      <option value="limited">Limited liability company</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Company name<span className="text-danger">*</span>
                      {sellerDataError?.company_name !== "valid" &&
                      sellerDataError?.company_name !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="company_name"
                      placeholder="Company name"
                      value={sellerData.company_name}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Email address<span className="text-danger">*</span>
                      {sellerDataError?.email !== "valid" &&
                      sellerDataError?.email !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={sellerData.email}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      readOnly
                      disabled={isImageUploading}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel flex">
                      Contact No.<span className="text-danger">*</span>
                      {sellerDataError?.contact_no !== "valid" &&
                      sellerDataError?.contact_no !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      <p className="text-blue-500 text-xs top-2 left-2">
                        Only USA phone numbers are allowed
                      </p>
                    </label>
                    <input
                      type="text"
                      name="contact_no"
                      className="form-control"
                      placeholder="Contact number"
                      value={sellerData.contact_no}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                      maxLength={12}
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel flex">
                      {" "}
                      Profile Picture image
                      <span className="text-danger">*</span>
                      {sellerDataError?.profile_image !== "valid" &&
                      sellerDataError?.profile_image !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      {isImageUploading ? (
                        <div className="flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 4335 4335"
                            width="20"
                            className="loading"
                            style={{ color: "#0ba934e0" }}
                          >
                            {" "}
                            <path
                              fill="#008DD2"
                              d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                            />
                          </svg>
                          <span
                            style={{ color: "#0ba934e0", fontSize: "12px" }}
                          >
                            Uploading.....
                          </span>
                        </div>
                      ) : null}
                    </label>

                    <input
                      type="file"
                      name="profile_image"
                      className="form-control"
                      onChange={(e) => setImage(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Country</label>

                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      value="USA"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Address</label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.personal_address !== "valid" &&
                    sellerDataError?.personal_address !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="personal_address"
                      placeholder="Address"
                      value={sellerData.personal_address}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">State</label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.state !== "valid" &&
                    sellerDataError?.state !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="state"
                      placeholder="State"
                      value={sellerData.state}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">City</label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.city !== "valid" &&
                    sellerDataError?.city !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      placeholder="City"
                      value={sellerData.city}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Postal/ZIP code </label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.area_code !== "valid" &&
                    sellerDataError?.area_code !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="area_code"
                      placeholder="Zipcode"
                      value={sellerData.area_code}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Street</label>
                    {/*<span className="text-danger"> * </span>

                                        {sellerDataError?.street !== "valid" &&
                                        sellerDataError?.street !== false ? (
                                            <span className="text-danger text-[12px]">
                                            {" "}
                                            This field is required !{" "}
                                            </span>
                                        ) : null}*/}
                    <input
                      type="text"
                      className="form-control"
                      name="street"
                      placeholder="Street"
                      value={sellerData.street}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="genericlabel">
                    {" "}
                    Tax Information <span className="text-danger">*</span>
                    {sellerDataError?.tax !== "valid" &&
                    sellerDataError?.tax !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <select
                      name="tax"
                      id=""
                      className="form-control"
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      value={sellerData?.tax}
                    >
                      <option value="" readonly>
                        Select Tax Information
                      </option>
                      <option value="tin">Tax identification Number</option>
                      <option value="ein">
                        Employer Identification Number
                      </option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Tax Information Number
                    </label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.taxnumber !== "valid" &&
                    sellerDataError?.taxnumber !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="taxnumber"
                      placeholder="Tax Information Number"
                      value={sellerData.taxnumber}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Bank Account Number
                      <span className="text-danger"> * </span>
                    </label>

                    {sellerDataError?.bank_account !== "valid" &&
                    sellerDataError?.bank_account !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="bank_account"
                      placeholder="Bank account"
                      value={sellerData.bank_account}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">
                      Account holder's name{" "}
                    </label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.beneficiary_name !== "valid" &&
                    sellerDataError?.beneficiary_name !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="beneficiary_name"
                      placeholder="Account holder's name"
                      value={sellerData.beneficiary_name}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Routing Number </label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.ifsc_code !== "valid" &&
                    sellerDataError?.ifsc_code !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="ifsc_code"
                      placeholder="Routing Number"
                      value={sellerData.ifsc_code}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Credit Card Number </label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.cardnumber !== "valid" &&
                    sellerDataError?.cardnumber !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <input
                      type="text"
                      className="form-control"
                      name="cardnumber"
                      placeholder="Credit Card Number"
                      value={sellerData.cardnumber}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Pickup Address</label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.pickup !== "valid" &&
                    sellerDataError?.pickup !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <textarea
                      type="text"
                      className="form-control"
                      name="pickup"
                      placeholder="Pickup Address"
                      value={sellerData.pickup}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Dropoff Address</label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.dropoff !== "valid" &&
                    sellerDataError?.dropoff !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <textarea
                      type="text"
                      className="form-control"
                      name="dropoff"
                      placeholder="Dropoff Address"
                      value={sellerData.dropoff}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel">Meet at public place</label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.meetpublicplace !== "valid" &&
                    sellerDataError?.meetpublicplace !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <textarea
                      type="text"
                      className="form-control"
                      name="meetpublicplace"
                      placeholder="Meet at public place address"
                      value={sellerData.meetpublicplace}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="genericlabel">
                    {" "}
                    Fulfillment Option <span className="text-danger">*</span>
                    {sellerDataError?.fulfillment_option !== "valid" &&
                    sellerDataError?.fulfillment_option !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <select
                      name="fulfillment_option"
                      id=""
                      className="form-control"
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      value={sellerData?.fulfillment_option}
                    >
                      <option value="" readonly>
                        Select Fulfillment options
                      </option>
                      <option value="UPS">UPS</option>
                      <option value="USPS">USPS</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="genericlabel">
                    {" "}
                    Verification Documents{" "}
                    <span className="text-danger">*</span>
                    {sellerDataError?.varifydoc !== "valid" &&
                    sellerDataError?.varifydoc !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                  </label>
                  <div className="form-group">
                    <select
                      name="varifydoc"
                      id=""
                      className="form-control"
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      value={sellerData?.varifydoc}
                    >
                      <option value="" readonly>
                        Select Documentation verifying the business's legal
                        existence and tax status
                      </option>
                      <option value="business_licenses">
                        Business Licenses
                      </option>
                      <option value="article">Articles of incorporation</option>
                      <option value="tax certificate">
                        Tax registration certificates
                      </option>
                      <option value="utility bills">Utility bills</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="genericlabel flex">
                      Document Verification Image
                      <span className="text-danger">*</span>
                      {sellerDataError?.varifydocimage !== "valid" &&
                      sellerDataError?.varifydocimage !== false ? (
                        <span className="text-danger text-[12px]">
                          {" "}
                          This field is required !{" "}
                        </span>
                      ) : null}
                      {isImageUploading ? (
                        <div className="flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 4335 4335"
                            width="20"
                            className="loading"
                            style={{ color: "#0ba934e0" }}
                          >
                            {" "}
                            <path
                              fill="#008DD2"
                              d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                            />
                          </svg>
                          <span
                            style={{ color: "#0ba934e0", fontSize: "12px" }}
                          >
                            Uploading.....
                          </span>
                        </div>
                      ) : null}
                    </label>

                    <input
                      type="file"
                      name="varifydocimage"
                      className="form-control"
                      onChange={(e) => setImage(e)}
                      onBlur={(e) => errorHandler(e)}
                    />
                  </div>
                </div>

                <div className="col-md-12 block">
                  <div className="form-group">
                    <label className="genericlabel">Product Description </label>
                    <span className="text-danger"> * </span>

                    {sellerDataError?.description !== "valid" &&
                    sellerDataError?.description !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <textarea
                      className="form-control "
                      name="description"
                      placeholder="Details about the products the business intends to sell, including categories, UPC/EAN/ISBN codes (if applicable), and product condition (new, used, refurbished)."
                      value={sellerData.description}
                      onChange={(e) => inputHandler(e)}
                      onBlur={(e) => errorHandler(e)}
                      disabled={isImageUploading}
                      style={{ height: "150px" }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="genericlabel">
                      {" "}
                      Marketplace Agreement{" "}
                    </label>

                    <span className="text-danger"> * </span>

                    {sellerDataError?.terms !== "valid" &&
                    sellerDataError?.terms !== false ? (
                      <span className="text-danger text-[12px]">
                        {" "}
                        This field is required !{" "}
                      </span>
                    ) : null}
                    <div className="form-check flex">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="termsAgreement"
                        checked={sellerData.terms}
                        name="terms"
                        onChange={(e) => {
                          const { checked } = e.target;
                          setSellerData({ ...sellerData, terms: checked });
                        }}
                        disabled={isImageUploading}
                      />
                      <label
                        className="form-check-label px-2"
                        htmlFor="termsAgreement"
                      >
                        <Link to="/marketplaceTerms" target="_blank">
                          {" "}
                          Agreement to savage seller terms and policies,
                          including the savage seller Services Business
                          Solutions Agreement.
                        </Link>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary savebtn w-full"
                    onClick={newUserCreatehandler}
                  >
                    {"Save"}
                  </button>
                </div>
                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>
            </div>
            {needpay === true ? (
              <>
                <Checkout
                  userData={userData}
                  sellerId={sellerId}
                  marketplaceapiurl={marketplaceapiurl}
                  changePayments={changePayments}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

function Checkout({ userData, sellerId, marketplaceapiurl, changePayments }) {
  const setPayer = async () => {
    const response = await axios.request({
      url: `${marketplaceapiurl}/seller/updatepaymentstatus`,
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("us_st_d")}`,
      },
      data: { seller_id: sellerId, paid: true },
    });
    const responsedData = await response?.data;
    if (responsedData.status === 200) {
      toast.success("New seller registered !!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.open("https://multivendor.savageseller.com/", "_blank");
      removeCookie("seller_id");
      changePayments(false);
      setTimeout(() => {
        window.location.href = "/profile"; // Replace '/profile' with the actual path you want to redirect to
      }, 5000);
    } else {
      toast.error(
        "Error occured while updating payment status!! Please contact with us",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  return (
    <div className="fixed bg-[#a3a3a3b3] top-0 bottom-0 left-0 right-0 z-[9]">
      <div className="border px-4 pb-4 pt-4 absolute top-[50%] bg-white left-[50%] translate-x-[-50%] translate-y-[-50%] w-[90%] md:w-[40%] rounded">
        {/* <div className="absolute top-0 right-0 bg-black border border-[#000] rounded-full translate-x-[10px] translate-y-[-15px] p-[10px]"><FaTimes color="#fff" /></div> */}
        <div className="py-5">
          <p className="text-center font-bold text-[20px]">
            Please Pay 500 USD as Registration Fee.
          </p>
        </div>
        <PayPalButtons
          style={{ layout: "vertical" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: "Registration Fee",
                  amount: {
                    value: "500",
                  },
                },
              ],
            });
          }}
          onCancel={(data, actions) => {
            // return actions.redirect(window.location.href)
            toast.warning("Please Pay 500 USD as Registration Fee!!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }}
          onApprove={async (data, actions) => {
            const details = await actions.order.capture();
            await setPayer();
          }}
          onError={(err) => {
            toast.error("Error occured during payment!!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }}
          message={{
            amount: 500,
            align: "right",
            color: "black",
            position: "top",
          }}
        />
      </div>
    </div>
  );
}

export default VendorRegisterforbusiness;
