import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { useUser } from "../contexts/UserProvider";
import axios from "axios";
import { apiurl } from "../config/config";
import { toast } from "react-toastify";
import LoaderSpiner from "../components/loader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { awsKeyId, awsSecretKey } from "../config/config";
import { v4 as uuidv4 } from "uuid";
import { getCookie } from "../config/coockies";
const aws = require("aws-sdk");

const CreateCommunities = () => {
  const token = getCookie("savage_token");
  const { userData } = useUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const communityHandle = searchParams.get("handle");
  const cid = searchParams.get("cid");

  const [formData, setFormData] = useState({
    title: "",
    about: "",
    hero_img: "",
    cover_img: "",
    // community: "",
    communityType: "",
  });

  const [protectedpassword, setProtectedPassword] = useState("");
  const [isProtected, setIsProtected] = useState(false);

  const initialError = {
    title: false,
    about: false,
    hero_img: false,
    cover_img: false,
    // community: false,
    communityType: false,
    protectedpassword: false,
  };
  const [error, setError] = useState(initialError);
  const [loading, setLoading] = useState(false);
  const [imgloading, setImgLoading] = useState({
    image1: false,
    image2: false,
  });

  // console.log('userData data is >>>>>', userData)

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name)
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setError((prevData) => ({ ...prevData, [name]: false }));
    if (name === "communityType") {
      setIsProtected(value === "protected");
    }
  };

  const validateForm = () => {
    const newErrors = { ...initialError };
    let hasErrors = false;
    Object.keys(formData).forEach((field) => {
      if (formData[field] === "") {
        newErrors[field] = true;
        hasErrors = true;
      }
    });
    setError(newErrors);
    return !hasErrors;
  };
  const ImageHandler = async (e) => {
    if (e.target.name == "hero_img") {
      setImgLoading({ ...imgloading, image1: true });
    } else {
      setImgLoading({ ...imgloading, image2: true });
    }
    try {
      const file = e.target.files[0];
      let myFile = file.name.split(".");
      let myFileType = myFile[myFile.length - 1];
      const s3 = new aws.S3({
        accessKeyId: awsKeyId,
        secretAccessKey: awsSecretKey,
      });
      const params = {
        Bucket: "savageseller",
        Key: `${uuidv4()}.${myFileType}`,
        Body: file,
        ContentType: file?.type,
      };
      let { Location } = await s3.upload(params).promise();
      if (e.target.name == "hero_img") {
        setImgLoading({ ...imgloading, image1: false });
      } else {
        setImgLoading({ ...imgloading, image2: false });
      }
      return Location;
    } catch (exception) {
      console.error(exception);
      return false;
    }
  };
  const setImage = async (e) => {
    const image = await ImageHandler(e);
    // console.log("Image----", image);
    setFormData({ ...formData, [e.target.name]: image });
  };

  const handleSubmitCommunitie = async () => {
    setLoading(true);
    try {
      if (validateForm() && (!isProtected || protectedpassword !== "")) {
        const createdata = {
          title: formData.title,
          about: formData.about,
          hero_img: formData.hero_img,
          cover_img: formData.cover_img,
          // community: formData.community,
          communityType: formData.communityType,
          password: protectedpassword,
          author: {
            _id: userData._id,
            username: `${userData.firstname} ${userData.lastname}`,
          },
          members: [
            {
              _id: userData._id,
              username: `${userData.firstname} ${userData.lastname}`,
              accountType: "admin",
            },
          ],
        };

        // console.log('createdata  >>>>>>>-----', createdata);

        const response = await axios.post(
          `${apiurl}/community/create`,
          createdata,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        // console.log('Create Community Response:', response.data);
        if (response.status === 200) {
          setLoading(false);
          setFormData({
            title: "",
            about: "",
            hero_img: "",
            cover_img: "",
            // community: "",
            communityType: "",
          });
          setProtectedPassword("");
          navigate("/communities");
          toast.success("Community Created Successfully");
        } else {
          setLoading(false);
          toast.error("data post failed");
        }
      } else {
        setLoading(false);
        toast.error("Please fill in all required fields.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Create Community Error:", error);
    }
  };

  const handleGetCommunity = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/community/handle/${communityHandle}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        setFormData(response.data.community);
      } else {
        setLoading(false);
        console.error("Error: Unable to retrieve community data");
      }

      // console.log('get community response', response)
    } catch (error) {
      setLoading(false);
      console.error("get community error", error);
    }
  };

  const handleUpdateCommunitie = async () => {
    setLoading(true);
    try {
      if (validateForm() && (!isProtected || protectedpassword !== "")) {
        const updatedData = {
          title: formData.title,
          about: formData.about,
          hero_img: formData.hero_img,
          cover_img: formData.cover_img,
          // community: formData.community,
          communityType: formData.communityType,
          password: protectedpassword,
          author: {
            _id: userData._id,
            username: `${userData.firstname} ${userData.lastname}`,
          },
          members: [
            {
              _id: userData._id,
              username: `${userData.firstname} ${userData.lastname}`,
              accountType: "admin",
            },
          ],
        };
        // console.log('updatedData  >>>>>>>-----', updatedData);
        const response = await axios.put(
          `${apiurl}/community/update-community/${cid}`,
          updatedData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        // console.log('update community response', response)
        if (response.data.success === true) {
          setLoading(false);
          toast.success(response.data.message);
          navigate("/communities");
        } else {
          setLoading(false);
          toast.error("Error: Unable to update community data");
        }
      } else {
        setLoading(false);
        toast.error("Please fill in all required fields.");
      }
    } catch (error) {
      setLoading(false);
      console.error("update community error", error);
    }
  };

  useEffect(() => {
    if (
      communityHandle !== undefined &&
      communityHandle !== null &&
      communityHandle !== ""
    ) {
      handleGetCommunity();
    }
  }, [communityHandle]);

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <Header />
      <div className="inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div
          className="bg-cover bg-center bg-black bg-opacity-50 w-full h-full px-4"
          style={{ backgroundImage: 'url("assets/communitiesbnr1.jpg")' }}
        >
          <div className="bg-white sm:p-8 p-4 rounded shadow w-full sm:w-5/12 m-auto my-4">
            <h2 className="text-2xl font-semibold mb-4">{communityHandle?"Update Community":"Create Community"}</h2>
            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 flex justify-start"
                htmlFor="title"
              >
                Community Title <span className="text-danger">*</span>
                {error.title && (
                  <p className="text-[#FF0000] sm:text-sm text-xs absolute  right-3">
                    Title is mandatory *
                  </p>
                )}
              </label>
              <input
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                placeholder="community title"
                name="title"
                value={formData.title}
                onChange={(e) => handleChange(e)}
                onBlur={validateForm}
              />
            </div>

            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 flex justify-start"
                htmlFor="about"
              >
                Community About <span className="text-danger">*</span>
                {error.about && (
                  <p className="text-[#FF0000] sm:text-sm text-xs absolute right-3">
                    About is mandatory *
                  </p>
                )}
              </label>
              <input
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                placeholder="community about"
                name="about"
                value={formData.about}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 flex justify-start"
                htmlFor="heroimage"
              >
                Hero Image <span className="text-danger">*</span>
                {!formData.hero_img && (
                  <p className="text-[#FF0000] sm:text-sm text-xs absolute  right-3">
                    Hero Image is mandatory *
                  </p>
                )}
                {imgloading.image1 ? (
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4335 4335"
                      width="20"
                      className="loading"
                      style={{ color: "#0ba934e0" }}
                    >
                      {" "}
                      <path
                        fill="#008DD2"
                        d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                      />
                    </svg>
                    <span style={{ color: "#0ba934e0" }}>Uploading.....</span>
                  </div>
                ) : null}
              </label>
              <input
    className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
    type="file"
    placeholder="hero image"
    name="hero_img"
    onChange={(e) => {
        const file = e.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
        if (file){
        if (file.size > maxSize) {
            alert("File size exceeds 5 MB. Please upload a smaller image.");
            e.target.value = null; // Clear the input field
            return;
        }

        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
            const width = img.width;
            const height = img.height;

            if (width < 100 || width > 400 || height < 100 || height > 400) {
              alert("The image dimensions must be between 100x100 pixels and 400x400 pixels.");
                e.target.value = null; // Clear the input field
            } else {
                setImage(e);
            }
        };
      }
    }}
    disabled={imgloading.image2 ? true : false}
    id="hero_img"
/>
{formData.hero_img?<img src={formData.hero_img} className="h-20 w-20"/>:""}
            </div>

            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 flex justify-start"
                htmlFor="coverimage"
              >
                Cover Image <span className="text-danger">*</span>
                {!formData.cover_img && (
                  <p className="text-[#FF0000] sm:text-sm text-xs absolute right-3">
                    Cover Image is mandatory *
                  </p>
                )}
                {imgloading.image2 ? (
                  <div className="flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 4335 4335"
                      width="20"
                      className="loading"
                      style={{ color: "#0ba934e0" }}
                    >
                      {" "}
                      <path
                        fill="#008DD2"
                        d="M3346 1077c41,0 75,34 75,75 0,41 -34,75 -75,75 -41,0 -75,-34 -75,-75 0,-41 34,-75 75,-75zm-1198 -824c193,0 349,156 349,349 0,193 -156,349 -349,349 -193,0 -349,-156 -349,-349 0,-193 156,-349 349,-349zm-1116 546c151,0 274,123 274,274 0,151 -123,274 -274,274 -151,0 -274,-123 -274,-274 0,-151 123,-274 274,-274zm-500 1189c134,0 243,109 243,243 0,134 -109,243 -243,243 -134,0 -243,-109 -243,-243 0,-134 109,-243 243,-243zm500 1223c121,0 218,98 218,218 0,121 -98,218 -218,218 -121,0 -218,-98 -218,-218 0,-121 98,-218 218,-218zm1116 434c110,0 200,89 200,200 0,110 -89,200 -200,200 -110,0 -200,-89 -200,-200 0,-110 89,-200 200,-200zm1145 -434c81,0 147,66 147,147 0,81 -66,147 -147,147 -81,0 -147,-66 -147,-147 0,-81 66,-147 147,-147zm459 -1098c65,0 119,53 119,119 0,65 -53,119 -119,119 -65,0 -119,-53 -119,-119 0,-65 53,-119 119,-119z"
                      />
                    </svg>
                    <span style={{ color: "#0ba934e0" }}>Uploading.....</span>
                  </div>
                ) : null}
              </label>
              <input
              className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              type="file"
              placeholder="cover image"
              name="cover_img"
              onChange={(e) => {
                  const file = e.target.files[0];
                  const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                  if (file){
                  if ( file.size > maxSize) {
                      alert("File size exceeds 5 MB. Please upload a smaller image.");
                      e.target.value = null; // Clear the input field
                      return;
                  }
          
                  const img = new Image();
                  img.src = URL.createObjectURL(file);
          
                  img.onload = () => {
                      const width = img.width;
                      const height = img.height;
          
                      if (width < 1500 || height < 400)  {
                        alert("The cover image size must be more than 1500*400 pixels.");
                        e.target.value = null; // Clear the input field
                    } else {
                        setImage(e);
                    }
                    
                  };
                }
              }}
              disabled={imgloading.image1 ? true : false}
              id="cover_img"
          />
          {formData.cover_img? <img src={formData.cover_img} className="h-20 w-20"/>:""}
          
            </div>

            {/* <div className="mb-4 relative">
                            <label className="block text-gray-700 text-sm font-bold mb-2 flex justify-start" htmlFor="community">
                                Community Name
                                {error.community && (
                                    <p className="text-[#FF0000] sm:text-sm text-xs absolute  right-0">
                                        Community name is mandatory *
                                    </p>
                                )}
                            </label>
                            <input
                                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                                type="text"
                                placeholder="community name"
                                name="community"
                                value={formData.community}
                                onChange={(e) => handleChange(e)}
                            />
                        </div> */}

            <div className="mb-4  relative">
              <div className="justify-between">
                <label
                  className="text-gray-700 text-sm font-bold mb-2 flex justify-start"
                  htmlFor="communityType"
                >
                  Community Type <span className="text-danger">*</span>
                  {error.communityType && (
                    <p className="text-[#FF0000] sm:text-sm text-xs">
                      Cummunity Type is mandatory *
                    </p>
                  )}
                </label>
                <div className="flex justify-between lg:w-1/2 sm:w-4/6 w-3/6 mb-4">
                  <div className="flex items-baseline	">
                    <input
                      className="flex items-baseline	border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      type="radio"
                      name="communityType"
                      value="private"
                      checked={formData.communityType === "private"}
                      onChange={(e) => handleChange(e)}
                    />
                    <label className="mx-2">Private</label>
                  </div>
                  <div className="flex items-baseline	">
                    <input
                      className="flex items-baseline	border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      type="radio"
                      name="communityType"
                      value="public"
                      checked={formData.communityType === "public"}
                      onChange={(e) => handleChange(e)}
                    />
                    <label className="mx-2">Public</label>
                  </div>

                  <div className="flex items-baseline	">
                    <input
                      className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      type="radio"
                      name="communityType"
                      value="protected"
                      checked={formData.communityType === "protected"}
                      onChange={(e) => {
                        handleChange(e);
                        setIsProtected(!isProtected);
                      }}
                    />
                    <label className="mx-2">Protected</label>
                  </div>
                </div>

                {isProtected === true ? (
                  <div className="mb-4 relative">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2 flex justify-start"
                      htmlFor="community"
                    >
                      Protected Password <span className="text-danger">*</span>
                      {protectedpassword === "" && (
                        <p className="text-[#FF0000] sm:text-sm text-xs absolute right-3 sm:top-0 bottom-[70px]">
                          Community Protected Password is mandatory *
                        </p>
                      )}
                    </label>
                    <input
                      className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      type="password"
                      placeholder="password"
                      value={protectedpassword}
                      onChange={(e) => setProtectedPassword(e.target.value)}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            {cid ? (
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
                type="button"
                onClick={handleUpdateCommunitie}
              >
                Update
              </button>
            ) : (
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
                type="button"
                onClick={handleSubmitCommunitie}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCommunities;
