import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../components/layout";
import { Link } from "react-router-dom";
import { apiurl } from "../config/config";
import TopBanner from "../components/topBanner";
import { getCookie } from "../config/coockies";
import LoaderSpiner from "../components/loader";

const Home = () => {
  const discussiondata = [
    {
      title: "Classy Marketplace",
      content: "Discuss the latest in technology and innovation.",
      imageUrl: "/assets/discussioncard.jpg",
    },
    {
      title: "Better Store",
      content: "Discuss the latest in technology and innovation.",
      imageUrl: "/assets/discussioncard.jpg",
    },
    {
      title: "Social Platform",
      content: "Discuss the latest in technology and innovation.",
      imageUrl: "/assets/discussioncard.jpg",
    },
    {
      title: "Technology Platform",
      content: "Discuss the latest in technology and innovation.",
      imageUrl: "/assets/discussioncard.jpg",
    },
  ];
  const blogdata = [
    {
      title: "Classy Marketplace",
      content:
        "Keep in mind the potential security implications and consider carefully whether this is appropriate for your use case. If you do choose to allow requests from all origins, make sure to implement other security measures to protect your server and its data.",
      imageUrl: "/assets/group-img.jpg",
    },
    {
      title: "Better Store",
      content:
        "Keep in mind the potential security implications and consider carefully whether this is appropriate for your use case. If you do choose to allow requests from all origins, make sure to implement other security measures to protect your server and its data.",
      imageUrl: "/assets/group-img.jpg",
    },
    {
      title: "Social Platform",
      content:
        "Keep in mind the potential security implications and consider carefully whether this is appropriate for your use case. If you do choose to allow requests from all origins, make sure to implement other security measures to protect your server and its data.",
      imageUrl: "/assets/group-img.jpg",
    },
  ];

  const [discussion, setDiscussion] = useState(discussiondata);
  const [blog, setblog] = useState(blogdata);
  const [communities, setCommunities] = useState([]);
  const token = getCookie("savage_token");
  const [loading, setLoading] = useState(false);

  const fetchCommunities = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/get`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        setCommunities(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    fetchCommunities();
  }, []);

  // console.log('communities >>>>>>>> ', communities)

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <Layout>
        <TopBanner name="homepage" />
        <section className="bg-gray-100 py-8">
          <div className="container mx-auto">
            <h2 className="text-2xl font-semibold mb-4 lg:text-left text-center px-5">
              Featured Communities
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-5">
              {communities &&
                communities
                  .filter((item) => item.communityType === "public")
                  .slice(0, 4)
                  .map((item, index) => {
                    return (
                      <div
                        className="bg-white p-4 rounded-md shadow-md"
                        key={index}
                      >
                        <Link to={`/community/${item.community_handle}`}>
                          <img
                            src={item.cover_img}
                            alt="Community Image"
                            className="w-full sm:h-64 h-auto object-cover mb-4 rounded-md"
                          />
                          <div className="mb-4 relative">
                            <h3 className="text-xl font-semibold mb-2 capitalize">
                              {item.title}
                            </h3>
                            <p className="text-gray-600  line-clamp-5">
                              {item.about}
                            </p>
                            <div className="text-xs text-black font-semibold absolute right-0 -bottom-5">
                              Created by :{" "}
                              <span className="text-gray-700 font-normal">
                                {item.author?.username}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
            </div>
          </div>
        </section>

        <section className="bg-gray-100 py-8 text-center">
          <div className="container mx-auto">
            <h2 className="text-2xl font-semibold mb-4">Community Articles</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 px-5">
              {blog.map((item, index) => {
                return (
                  <div
                    className="bg-white p-4 rounded-md shadow-md"
                    key={index}
                  >
                    <img
                      src="/assets/blog-1.jpg"
                      alt="Community Image"
                      className="w-full h-[200px] object-cover mb-4 rounded-md"
                    />
                    <h3 className="text-xl font-semibold mb-2 capitalize">
                      {item.title}
                    </h3>
                    <p className="text-gray-600"></p>Keep in mind the potential
                    security implications and consider carefully whether this is
                    appropriate for your use case. If you do choose to allow
                    requests from all origins, make sure to implement other
                    security measures to protect your server and its data.
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Home;
