import axios from "axios";
import React, { useState } from "react";
import { apiurl } from "../config/config";
import { toast } from "react-toastify";
import LoaderSpiner from "./loader";
import { useParams, useNavigate } from "react-router-dom";

const AcceptInviteCommunity = () => {
  const [loading, setLoading] = useState(false);
  const { userid, cid } = useParams();
  const navgate = useNavigate();

  const handleAcceptInviteCommunity = async () => {
    setLoading(true);
    try {
      const data = {
        userId: userid,
        communityId: cid,
      };
      const response = await axios.post(
        `${apiurl}/community/accept-invite`,
        data
      );
      if (response.data.success === true) {
        setLoading(false);
        toast.success(response.data.message);
        navgate("/login");
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("accept community error", error);
    }
  };

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <div className="flex justify-center items-center h-screen bg-[#E0E9F7] p-4">
        <div className="mt-4 space-y-4 lg:mt-5 p-4 md:space-y-5 bg-white rounded shadow-2xl dark:border md:mt-0 sm:p-10 sm:w-1/2 sm:mx-2 md:w-2/3 lg:w-1/2 xl:w-1/3">
          <h2 className="mb-14 text-xl font-bold text-[#005AE6] md:text-2xl text-center">
            Accept Invite Community
          </h2>
          <div className="border border-[#005AE6] rounded-md p-4 relative">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="bg-[#005AE6] w-20 h-20 text-center border rounded-full flex items-center justify-center">
                <button className="text-white font-semibold text-2xl"></button>
              </div>
            </div>
            <div className="mt-8">
              <p className="block mb-4 text-xl text-gray-900 text-center font-semibold">
                Accept Invite Community
              </p>
              <h4 className="block mb-2 text-sm font-medium text-gray-700 text-center">
                Please click on the accept invite button for verify
              </h4>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <button
              type="submit"
              className="w-3/4 text-white hover:text-[#005AE6] border border-[#005AE6] hover:bg-white bg-[#005AE6] font-medium rounded-lg text-sm px-5 py-2.5 text-center transition-all duration-500"
              onClick={handleAcceptInviteCommunity}
            >
              Accept Invite Community
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptInviteCommunity;
