import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoutePage from "./routes";
import { Fragment } from "react";
import UserProvider from "./contexts/UserProvider";
import CallContextProvider from "./contexts/CallContext";
import AudioCallContext from "./contexts/audioCallContext";
import GroupCallContextProvider from "./contexts/groupCallContext";
import IncomingCall from "./components/incomingCall";
import IncomingAudioCall from "./components/incomingAudioCall";
import IncomingGroupCall from "./components/incomingGroupCall";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {
  VideoCom,
  AudioCallComponent,
  GroupCallComponent,
} from "./components/VideoCom";

const initialOptions = {
  "client-id":
    "AcZ05Zr-hgHhDwIqDhIZJb-Nkm0hJDb7HBF_naeWs66sVKrdleL5-wFPeX9r0NgyXCANk6-8iMjItDib",
  // "client-id": "AdLzRW18VHoABXiBhpX2gf0qhXwiW4MmFVHL69V90vciCg_iBLGyJhlf7EuWtFcdNjGiDfrwe7rmhvMZ",
  currency: "USD",
  intent: "capture",
  "enable-funding": "venmo",
};

function App() {
  return (
    <Fragment>
      <PayPalScriptProvider options={initialOptions}>
        <UserProvider>
          <CallContextProvider>
            <GroupCallContextProvider>
              <AudioCallContext>
                <RoutePage />
                <ToastContainer />
                <IncomingCall />
                <IncomingAudioCall />
                <IncomingGroupCall />
                <VideoCom />
                <AudioCallComponent />
                <GroupCallComponent />
              </AudioCallContext>
            </GroupCallContextProvider>
          </CallContextProvider>
        </UserProvider>
      </PayPalScriptProvider>
    </Fragment>
  );
}

export default App;
