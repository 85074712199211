import React, { createContext, useState, useRef, useEffect } from "react";
import global from "global";
import * as process from "process";
import { socket } from "../config/socket";
import Peer from "simple-peer";
import { useUser } from "./UserProvider";

global.process = process;

export const AudioCallContext = createContext();

export default function AudioCallContextProvider({ children }) {
  const { userData } = useUser();
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [isAudioCalling, setIsAudioCalling] = useState(false);
  const [peer, setPeer] = useState(null);
  const [callAccepted, setCallAccepted] = useState(false);
  const [audiocall, setAudioCall] = useState({});
  const [isCallEnd, setIsCallEnd] = useState(false);
  const [isAudioIncomingCall, setIsAudioIncomingCall] = useState(false);
  const remoteAudioCallRef = useRef(null);
  const [myMicStatus, setMyMicStatus] = useState(true);
  const [remoteMicStatus, setRemoteMicStatus] = useState();

  const openMediaDevices = async (constraints) => {
    try {
      return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
      console.error("Error accessing media devices.", err);
      throw err;
    }
  };

  const AudioCalluser = async (id) => {
    setIsAudioCalling(true);
    const constraints = { audio: true };
    try {
      const myStream = await openMediaDevices(constraints);
      setLocalStream(myStream);
      const peer = new Peer({
        initiator: true,
        trickle: false,
        stream: myStream,
      });
      peer.on("signal", (data) => {
        socket.emit("audioCallUser", {
          userToCall: id,
          signalData: data,
          from: userData?._id,
          name: userData?.name,
        });
      });
      peer.on("stream", (currentStream) => {
        if (remoteAudioCallRef.current) {
          remoteAudioCallRef.current.srcObject = currentStream;
        }
        setRemoteStream(currentStream);
      });
      socket.on("audioCallAccepted", (data) => {
        console.log("data", data);
        setCallAccepted(true);
        setAudioCall({ to: data.from, from: data.to });
        peer.signal(data.signal);
      });
      setPeer(peer);
    } catch (error) {
      console.error("Failed to get media stream:", error);
      setIsAudioCalling(false);
    }
  };

  const answerAudioCall = async () => {
    setIsAudioCalling(true);
    const constraints = { audio: true };
    try {
      const stream = await openMediaDevices(constraints);
      setLocalStream(stream);
      const peer = new Peer({
        initiator: false,
        trickle: false,
        stream: stream,
      });
      peer.on("signal", (data) => {
        socket.emit("answerAudioCall", {
          signal: data,
          to: audiocall.from,
          from: userData?._id,
        });
      });
      peer.on("stream", (currentStream) => {
        if (remoteAudioCallRef.current) {
          remoteAudioCallRef.current.srcObject = currentStream;
        }
        setRemoteStream(currentStream);
      });
      peer.signal(audiocall.signal);
      setPeer(peer);
      setCallAccepted(true);
      setIsAudioIncomingCall(false);
    } catch (error) {
      console.error("Failed to get media stream:", error);
      setIsAudioCalling(false);
    }
  };

  const DropAudioCall = (id, from) => {
    console.log("id", id, "from", from);
    setIsCallEnd(true);
    setIsAudioCalling(false);
    setIsAudioIncomingCall(false);
    socket.emit("endAudioCall", {
      to: id,
      from: from,
    });
    window.location.reload();
    if (peer) {
      peer.destroy();
    }
  };

  const updateAudioCall = () => {
    setMyMicStatus((currentStatus) => {
      if (localStream) {
        socket.emit("updateMyMedia", {
          type: "audio",
          currentMediaStatus: !currentStatus,
          id: userData?._id,
        });
        localStream.getAudioTracks()[0].enabled = !currentStatus;
      }
      return !currentStatus;
    });
  };

  const leaveAudioCall = () => {
    socket.emit("declineAudioCall", audiocall.from);
    setIsCallEnd(true);
    setIsAudioCalling(false);
    setIsAudioIncomingCall(false);
  };

  useEffect(() => {
    socket.on("incoming_audio_call", (data) => {
      setAudioCall(data);
      setIsAudioIncomingCall(true);
      setIsAudioCalling(true);
    });

    socket.on("updateRemoteMedia", ({ type, currentMediaStatus }) => {
      if (type === "audio") {
        setRemoteMicStatus(currentMediaStatus);
      }
    });
  }, [socket, peer]);

  useEffect(() => {
    socket.on("audiocalldroped", () => {
      console.log("audiocalldroped");
      setIsCallEnd(true);
      setIsAudioCalling(false);
      setCallAccepted(false);
      setIsAudioIncomingCall(false);
      window.location.reload();
    });
  }, [socket, peer]);

  useEffect(() => {
    socket.on("audioCallDeclined", () => {
      setIsCallEnd(true);
      setIsAudioCalling(false);
      setCallAccepted(false);
      setIsAudioIncomingCall(false);
      if (peer) {
        peer.destroy();
        window.location.reload();
      }
    });
  }, [socket, peer]);

  return (
    <AudioCallContext.Provider
      value={{
        localStream,
        remoteStream,
        isAudioCalling,
        remoteAudioCallRef,
        callAccepted,
        isAudioIncomingCall,
        audiocall,
        myMicStatus,
        remoteMicStatus,
        isCallEnd,
        AudioCalluser,
        answerAudioCall,
        DropAudioCall,
        updateAudioCall,
        leaveAudioCall,
      }}
    >
      {children}
      <audio ref={remoteAudioCallRef} autoPlay />
    </AudioCallContext.Provider>
  );
}
