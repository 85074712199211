import React, { createContext, useState, useRef, useEffect } from "react";
import global from "global";
import * as process from "process";
import { socket } from "../config/socket";
import Peer from "simple-peer";
import { useUser } from "./UserProvider";

global.process = process;

export const CallContext = createContext();

export default function CallContextProvider({ children }) {
  // console.log("socket", socket);
  const { userData } = useUser();
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [isCalling, setIsCalling] = useState(false);
  const _user = JSON.parse(localStorage.getItem("user"));
  const [peer, setpeer] = useState(null);
  const [callAccepted, setcallAccepted] = useState(false);
  const [call, setcall] = useState({});
  const [called, setcalled] = useState({});
  const [isCallend, setisCallend] = useState(false);
  const [Isincomingcall, setIsincomingcall] = useState(false);
  const RemoteVideoRef = useRef();
  const MyVideoRef = useRef();
  const [MyVideoStatus, setMyVideoStatus] = useState(true);
  const [MyMicStatus, setMyMicStatus] = useState(true);
  const [RemoteVideoStatus, setRemoteVideoStatus] = useState();
  const [RemoteMicStatus, setRemoteMicStatus] = useState();

  const openMediaDevices = async (constraints) => {
    try {
      return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
      console.error("Error accessing media devices.", err);
      throw err;
    }
  };
  const Calluser = async (id) => {
    setIsCalling(true);
    try {
      const mystream = await openMediaDevices({ video: true, audio: true });
      setLocalStream(mystream);
      MyVideoRef.current.srcObject = mystream;
      const peer = new Peer({
        initiator: true,
        trickle: false,
        stream: mystream,
      });
      peer.on("signal", (data) => {
        socket.emit("Calluser", {
          userToCall: id,
          signalData: data,
          from: userData?._id,
          name: userData?.name,
          type: "both",
        });
      });
      setcall((prev) => {
        return { ...prev, to: userData?._id, from: id };
      })
      peer.on("stream", (currentstream) => {
        console.log("RemoteVideoRef", RemoteVideoRef);
        RemoteVideoRef.current.srcObject = currentstream;
      });
      socket.on("callaccepted", (data) => {
        setcallAccepted(true);
        setcall({ to: data.from, from: data.to });
        peer.signal(data.signal);
      });
      // console.log("peer 68", peer);
      setpeer(peer);
    } catch (error) {
      console.error("Failed to get media stream:", error);
      setIsCalling(false);
    }
  };
  const AnswerCall = async () => {
    setIsCalling(true);
    try {
      const stream = await openMediaDevices({ video: true, audio: true });
      setLocalStream(stream);
      MyVideoRef.current.srcObject = stream;
      const peer = new Peer({
        initiator: false,
        trickle: false,
        stream: stream,
      });
      peer.on("signal", (data) => {
        socket.emit("answer_call", {
          signal: data,
          to: call.from,
          type: "both",
          from: userData?._id,
        });
      });
      peer.on("stream", (currentstream) => {
        RemoteVideoRef.current.srcObject = currentstream;
      });
      peer.signal(call.signal);
      setpeer(peer);
      // console.log("accept call peer: ", peer);
      setcallAccepted(true);
      setIsincomingcall(false);
    } catch (error) {
      console.error("Failed to get media stream:", error);
      setIsCalling(false);
    }
  };
  const DropCall = (id, from) => {
    if (peer) {
      console.log("id", id, "from", from);
      const streams = peer.streams || [];
      streams.forEach((stream) => {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      });
      peer.destroy();
      setisCallend(true);
      setIsCalling(false);
      setIsincomingcall(false);
      socket.emit("dropCall", {
        id: id,
      });
      socket.emit("dropCall", {
        id: from,
      });
      const peer2 = new Peer({
        initiator: false,
        trickle: false,
        stream: null,
      });
      setpeer(peer2);
      // window.location.reload();
    }
  };
  const UpdateVideo = () => {
    setMyVideoStatus((currentStatus) => {
      socket.emit("updateMyMedia", {
        type: "mic",
        currentMediaStatus: !currentStatus,
      });
      localStream.getVideoTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };
  const UpdateAudio = () => {
    setMyMicStatus((currentStatus) => {
      socket.emit("updateMyMedia", {
        type: "video",
        currentMediaStatus: !currentStatus,
      });
      localStream.getAudioTracks()[0].enabled = !currentStatus;
      return !currentStatus;
    });
  };
  const LeaveCall = () => {
    socket.emit("DeclineCall", call.from);
    setisCallend(true);
    setIsCalling(false);
    setIsincomingcall(false);
  };

  useEffect(() => {
    socket.on("incoming_call", (data) => {
      // console.log("data 151", data);
      setcall(data);
      setIsincomingcall(true);
      setIsCalling(true);
    });

    socket.on("updateRemoteMedia", ({ type, currentMediaStatus }) => {
      if (currentMediaStatus !== null || currentMediaStatus) {
        switch (type) {
          case "video":
            setRemoteVideoStatus(currentMediaStatus);
            break;
          case "mic":
            setRemoteMicStatus(currentMediaStatus);
            break;
          default:
            setRemoteMicStatus(currentMediaStatus[0]);
            setRemoteVideoStatus(currentMediaStatus[1]);
            break;
        }
      }
    });
  }, [socket, peer]);

  useEffect(() => {
    socket.on("calldroped", () => {
      // console.log("calldroped");
      setisCallend(true);
      setIsCalling(false);
      setcallAccepted(false);
      setIsincomingcall(false);
      if (peer) {
        const streams = peer.streams || [];
        streams.forEach((stream) => {
          stream.getTracks().forEach(track => {
            track.stop();
          });
        });
        peer.destroy();
        const peer2 = new Peer({
          initiator: false,
          trickle: false,
          stream: null,
        });
        setpeer(peer2);
        window.location.reload();
      }
    });
  }, [socket, peer]);

  useEffect(() => {
    socket.on("calldecline", () => {
      setisCallend(true);
      setIsCalling(false);
      setcallAccepted(false);
      setIsincomingcall(false);
      if (peer) {
        const streams = peer.streams || [];
        streams.forEach((stream) => {
          stream.getTracks().forEach(track => {
            track.stop();
          });
        });
        peer.destroy();
        const peer2 = new Peer({
          initiator: false,
          trickle: false,
          stream: null,
        });
        setpeer(peer2);
        window.location.reload();
      }
    });
  }, [socket, peer]);

  // useEffect(() => {
  //   console.log("peer 209", peer);
  // }, [peer]);

  return (
    <CallContext.Provider
      value={{
        localStream,
        remoteStream,
        isCalling,
        MyVideoRef,
        RemoteVideoRef,
        callAccepted,
        Isincomingcall,
        call,
        MyMicStatus,
        MyVideoStatus,
        RemoteVideoStatus,
        RemoteMicStatus,
        isCallend,
        Calluser,
        AnswerCall,
        DropCall,
        UpdateVideo,
        UpdateAudio,
        LeaveCall,
      }}
    >
      {children}
    </CallContext.Provider>
  );
}