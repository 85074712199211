import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";
import { getCookie } from "../config/coockies";
import { apiurl } from "../config/config";
import { toast } from "react-toastify";
import LoaderSpiner from "./loader";
import axios from "axios";
import { FaSearch} from "react-icons/fa";
const MyFollowers = ({ activeTab, handleGetFollowers, followers }) => {
  const { userData } = useUser();
  const token = getCookie("savage_token");
  const [loading, setLoading] = useState(false);
  const [tabHeader, setTabHeader] = useState();
  const [followReq, setgetFollowReq] = useState([]);
  const [following, setFollowing] = useState([]);
  const [suggestMember, setSuggestMember] = useState([]);
  const [sendRequest, setSendRequest] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleUniversalSearch = async () => {
    try {
      const response = await axios.get(`${apiurl}/search?by=${searchQuery}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      setSuggestMember(response.data?.data?.users || []);
      // console.log('universal search response', response)
    } catch (error) {
      console.error("Universal search error:", error);
    }
  };
  useEffect(() => {
    if (activeTab === "myfollowers") {
      setTabHeader("My Followers");
      handleGetFollowers();
    } else if (activeTab === "following") {
      setTabHeader("My Following");
      handleGetFollowing();
    } else if (activeTab === "requests") {
      setTabHeader("All Requests");
      handleGetFollowRequest();
    } else {
      setTabHeader("");
    }
  }, [activeTab]);

  const handleGetFollowRequest = async () => {
    if (activeTab === "requests") {
      try {
        setLoading(true);
        const response = await axios.get(
          `${apiurl}/follow/get-request/${userData._id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        // console.log('get follow request response >>>>>>>', response)
        if (response.data.success === true) {
          setLoading(false);
          const responseData = response.data;
          setgetFollowReq(responseData?.response);
        } else {
          setLoading(false);
          console.error(response.data.message);
        }
      } catch (error) {
        setLoading(false);
        console.error("get follow request", error);
      }
    }
  };

  const handleGetFollowing = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiurl}/follow/following/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('myfollowing response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        setFollowing(response?.data?.following);
        // toast.success(response.data.message)
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("all following", error);
    }
  };

  const handleAcceptRequest = async (senderId) => {
    // console.log("senderId", senderId)
    // console.log("receiverId", userData._id)
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/accepte-request`,
        { senderId: senderId, receiverId: userData._id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('accept follow request response >>>>>>>', response)
      if (response.data.success === true) {
        await handleGetFollowRequest();
        setLoading(false);
        toast.success(response.data.message);
        handleGetFollowers();
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("accepte-request", error);
    }
  };

  const handleRemoveRequest = async (senderId) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/decline-request`,
        { receiverId: userData._id, senderId: senderId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('remove follow response >>>>>>>', response)
      if (response.data.success === true) {
        handleGetFollowRequest();
        handleGetFollowing();
        setLoading(false);
        toast.success(response.data.message);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("remove-request", error);
    }
  };

  const handleRemoveFollower = async (selectedfriendId) => {
    try {
      setLoading(true);
      let data;
      if (activeTab === "myfollowers") {
        data = {
          userId: userData._id,
          friendId: selectedfriendId,
        };
      } else {
        data = {
          userId: selectedfriendId,
          friendId: userData._id,
        };
      }
      // console.log('my data is ---------------->', data)
      const response = await axios.delete(`${apiurl}/follow/unfriend`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      });
      // console.log("unfriend response --------------->>>", response)
      if (response.data.success) {
        toast.success(response.data.message);
        if (handleGetFollowers) handleGetFollowers();
        if (handleGetFollowing) handleGetFollowing();
        handleGetSuggestMemberList();
        setLoading(false);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("remove-request", error);
    }
  };

  const handleGetSuggestMemberList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiurl}/follow/get-suggestion-user/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('Suggest all member data is >>>>>>>>>>>', response.data);
      if (response.data.success === true) {
        setLoading(false);
        setSuggestMember(response.data?.suggestedUsers);
      } else {
        setLoading(false);
        console.error("no member available");
      }
    } catch (error) {
      console.error("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetSuggestMemberList();
  }, []);
  const [isRequest, setIsRequest] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const handleSendFollowRequest = async (receiverId,usertype) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/send-request`,
        { senderId: userData._id, receiverId: receiverId,userType:usertype },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('send follow request response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        if(response.data.friendRequest)
          {
            setSendRequest((prev) => ({
              ...prev,
              [receiverId]: true,
            }));
            setIsFollow(false);
          }
          else{
            setSendRequest((prev) => ({
              ...prev,
              [receiverId]: false,
            }));
            setIsFollow(true);
          }
        handleGetSuggestMemberList();
        toast.success(response.data.message);
        // setSendRequest((prev) => ({
        //   ...prev,
        //   [receiverId]: true,
        // }));
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("send follow request", error);
    }
  };

  return (
    <>
      {loading && <LoaderSpiner text="Loading ..." />}
      <div className="bg-gray-200 p-4">
        <div className="bg-white p-4 rounded-xl">
          <div className="flex justify-between mx-4 mb-9">
            <p className="ml-0 text-center text-[18px] sm:ml-10 xl:text-2xl font-bold sm:mb-0 md:text-2xl xl:mb-4">
              {tabHeader}
            </p>
          </div>

          {activeTab === "requests" ? (
            followReq && followReq.length > 0 ? (
              <div className="mx-auto">
                {followReq.map((req, index) => (
                  <div className="sm:mx-10 mx-0" key={index}>
                    <div className="sm:p-5 py-3 flex items-center justify-between cursor-pointer hover:bg-gray-200 transition-all duration-500">
                      <div className="flex items-center w-full sm:w-28 sm:h-16 ml-3 sm:ml-0 py-3">
                        <img
                          className="rounded-full h-20 sm:w-20 w-28"
                          src={req.profileimage}
                          alt="request image"
                        />
                      </div>
                      <div className="flex  sm:flex-row sm:justify-between sm:items-center w-full flex-col items-start ml-6 ">
                        <Link to={`/profile/${req.senderId}`}>
                          <div className="ml-2 flex flex-col">
                            <div className="leading-snug sm:text-lg text-sm text-gray-900 font-semibold mb-1 capitalize">
                              {req.senderUsername}
                            </div>
                            <div className="leading-snug sm:text-sm text-xs text-gray-600">
                              {req.senderEmail}
                            </div>
                          </div>
                        </Link>
                        <div className="flex justify-between items-center sm:mt-0 mt-2">
                          <button
                            className="sm:py-2 py-[2px] px-5  sm:text-md text-xs sm:font-semibold font-normal bg-red-100 mr-2 text-red-500 border border-red-600 rounded-full  hover:bg-red-600 hover:text-white transition-all duration-300"
                            onClick={() => handleRemoveRequest(req.senderId)}
                          >
                            Remove
                          </button>
                          <button
                            className="sm:py-2 py-[2px] px-5 sm:text-md text-sm font-semibold text-blue-500 border border-blue-600 rounded-full  hover:bg-blue-600 hover:text-white transition-all duration-300"
                            onClick={() => handleAcceptRequest(req.senderId)}
                          >
                            Accept
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center mb-10">
                <p className="sm:text-3xl text-xl font-semibold">
                  No request available
                </p>
              </div>
            )
          ) : null}

          {activeTab === "myfollowers" ? (
            followers && followers.length > 0 ? (
              <div className="mx-auto">
                {followers.map((follower, index) => (
                  <div className="sm:mx-10 mx-0" key={index}>
                    <div className="sm:p-5 py-3 flex items-center justify-between cursor-pointer hover:bg-gray-200 transition-all duration-500">
                      <Link to={`/profile/${follower.userId}`}>
                        <div className="flex items-center w-full sm:w-28 sm:h-16 ml-3 sm:ml-0 py-3">
                          <img
                            className="rounded-full h-20 sm:w-20 w-28"
                            src={follower.hero_img}
                            alt="follower image"
                          />
                        </div>
                      </Link>
                      <div className="flex  sm:flex-row sm:justify-between sm:items-center w-full flex-col items-start ml-6 ">
                        <Link to={`/profile/${follower.userId}`}>
                          <div className="ml-2 flex flex-col">
                            <div className="leading-snug sm:text-lg text-sm text-gray-900 font-semibold mb-1 capitalize">
                              {follower.username}
                            </div>
                            <div className="leading-snug sm:text-sm text-xs text-gray-600">
                              {follower.userHandle}
                            </div>
                          </div>
                        </Link>
                        <div className="flex justify-between items-center sm:mt-0 mt-2">
                          <button
                            className="sm:py-2 py-[2px] px-5  sm:text-md text-xs sm:font-semibold font-normal bg-red-100 mr-2 text-red-500 border border-red-600 rounded-full  hover:bg-red-600 hover:text-white transition-all duration-300"
                            onClick={() =>
                              handleRemoveFollower(follower.userId)
                            }
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center mb-10">
                <p className="sm:text-3xl text-xl font-semibold">
                  No Follower available
                </p>
              </div>
            )
          ) : null}
          {activeTab === "following" ? (
            following && following.length > 0 ? (
              <div className="mx-auto">
                {following.map((followingItem, index) => (
                  <div className="sm:mx-10 mx-0" key={index}>
                    <div className="sm:p-5 py-3 flex items-center justify-between cursor-pointer hover:bg-gray-200 transition-all duration-500">
                      <div className="flex items-center w-full sm:w-28 sm:h-16 ml-3 sm:ml-0 py-3">
                        <img
                          className="rounded-full h-20 sm:w-20 w-20"
                          src={followingItem.hero_img}
                          alt="follower image"
                        />
                      </div>
                      <div className="flex sm:flex-row sm:justify-between sm:items-center w-full flex-col items-start ml-6">
                        <Link to={`/profile/${followingItem.userId}`}>
                          <div className="ml-2 flex flex-col">
                            <div className="leading-snug sm:text-lg text-sm text-gray-900 font-semibold mb-1 capitalize">
                              {followingItem.username}
                            </div>
                            <div className="leading-snug sm:text-sm text-xs text-gray-600">
                              {followingItem.userHandle}
                            </div>
                          </div>
                        </Link>
                        <div className="flex justify-between items-center sm:mt-0 mt-2">
                          <button
                            className="sm:py-2 py-[2px] px-5 sm:text-md text-sm sm:font-semibold font-normal bg-red-100 mr-2 text-red-500 border border-red-600 rounded-full hover:bg-red-600 hover:text-white transition-all duration-300"
                            onClick={() =>
                              handleRemoveFollower(followingItem.userId)
                            }
                          >
                            UnFollow
                          </button>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : <div className="flex justify-center items-center mb-10">
              <p className="text-md font-semibold">No Following is available</p>
            </div>
            
          ) : null}

          {activeTab === "following" ? (
            <>
              <div className="flex justify-center items-center mb-10">
                <p className="sm:text-3xl text-xl font-semibold">
                  Member Suggestion
                </p>
              </div>
              <div className="mx-auto">
              <div className="relative flex items-center mb-2 justify-center  rounded-md w-full md:flex px-3 py-0">
              <input
                      type="search"
                      className="py-3 px-4 w-1/2 outline-none bg-[#EFF5FF] rounded-md"
                      placeholder="Search User"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyUp={handleUniversalSearch}
                      
                    />
                       
                            </div>
                {suggestMember &&
                  suggestMember.map((alluser, index) => (
                    <div className="sm:mx-10 mx-0" key={index}>
                      <hr />
                      <div className="sm:p-5 py-3 flex items-center justify-between cursor-pointer hover:bg-gray-200 transition-all duration-500">
                        <Link to={`/profile/${alluser._id}`}>
                          <div className="flex items-center w-full sm:w-28 sm:h-16 ml-3 sm:ml-0 py-3">
                            <img
                              className="rounded-full h-20 sm:w-20 w-28"
                              src={alluser.hero_img}
                              alt="follower image"
                            />
                          </div>
                        </Link>
                        <div className="flex sm:flex-row sm:justify-between sm:items-center w-full flex-col items-start ml-6 ">
                          <Link to={`/profile/${alluser._id}`}>
                            <div className="ml-2 flex flex-col">
                              <div className="leading-snug sm:text-lg text-sm text-gray-900 font-semibold mb-1 capitalize">
                                {alluser.name}
                              </div>
                              <div className="leading-snug sm:text-sm text-xs text-gray-600">
                                {alluser.userHandle}
                              </div>
                              {/*<div className="leading-snug sm:text-sm text-xs text-gray-600">
                                {alluser.location}
                              </div>*/}
                            </div>
                          </Link>

                          {sendRequest[alluser._id]? (
                            <div className="flex justify-between items-center sm:mt-0 mt-2">
                              <button className="sm:py-2 py-[2px] px-5 sm:text-md text-sm font-semibold text-green-600 border border-green-600 rounded-full hover:bg-green-600 hover:text-white transition-all duration-300">
                                Requested
                              </button>
                            </div>
                          ) : (
                            <div className="flex justify-between items-center sm:mt-0 mt-2">
                              <button
                                className="sm:py-2 py-[2px] px-5 sm:text-md text-xs font-semibold text-blue-500 border border-blue-600 rounded-full hover:bg-blue-600 hover:text-white transition-all duration-300"
                                onClick={() =>
                                  handleSendFollowRequest(alluser._id,alluser.userType)
                                }
                              >
                                Follow +
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MyFollowers;
