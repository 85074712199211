import axios from 'axios';
import React, { useState } from 'react';
import { apiurl } from '../config/config';
import { IoCloudUploadOutline } from "react-icons/io5";
import { awsKeyId, awsSecretKey } from "../config/config";
import { v4 as uuidv4 } from 'uuid';
import { useUser } from '../contexts/UserProvider';
import { getCookie } from '../config/coockies';
import { toast } from 'react-toastify';
import LoaderSpiner from './loader';
const aws = require('aws-sdk');

const ImageUpload = ({ setIsBgImg, setIsProfileImg, heading, text, uploadType, handleGetProfileData }) => {
    const { userData } = useUser()
    const token = getCookie("savage_token")
    const [updateData, setUpdateData] = useState({
        "cover_img": "",
        "hero_img": ""
    });
    const [loading, setLoading] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);
    const [imgLoading, setImgLoading] = useState(false);

    const handleUpload = async (imageType, imageUrl) => {
        setImgLoading(true);
        setLoading(true);
        try {
            const updatedData = {
                [imageType]: imageUrl
            };
            const UserId = { id: userData._id };
            const response = await axios.put(`${apiurl}/user/update/${UserId.id}`, updatedData, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token
                }
            });
            await handleGetProfileData();
            if (response.status === 200) {
                await handleGetProfileData();
                setIsBgImg(false);
                setIsProfileImg(false);
                setLoading(false);
                toast.success('Profile Updated Successfully');
            } else {
                setIsBgImg(false);
                setIsProfileImg(false);
                setLoading(false);
                toast.error('Failed to Update Data');
            }
        } catch (error) {
            setIsBgImg(false);
            setIsProfileImg(false);
            setLoading(false);
            console.error('Profile update error', error);
        }
        setImgLoading(false);
    };



    // console.log('selectedImage------------------------------>>>>', selectedImage)

    const ImageHandler = async (e) => {
        try {
            setImgLoading(true);
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: awsKeyId,
                secretAccessKey: awsSecretKey
            });
            const params = {
                Bucket: "savageseller",
                Key: `${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let { Location } = await s3.upload(params).promise();
            return Location;
        } catch (exception) {
            console.log(exception);
            return false;
        } finally {
            setImgLoading(false);
        }
    };

    const setImage = async (e) => {
        const image = await ImageHandler(e);
        const imageType = uploadType === "profileBackgroundImage" ? "cover_img" : "hero_img";
        if (image) {
            handleUpload(imageType, image);
        }
    };

    const handleClosePopup = () => {
        setIsBgImg(false);
        setIsProfileImg(false);
    }

    return (
        <div>
            {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
            <div className="fixed inset-0 overflow-y-auto shadow-2xl">
                <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-3/4  sm:p-6">
                        <p className="text-lg sm:text-xl text-gray-600 font-semibold pb-4 w-11/12">{heading}</p>
                        <button className="absolute top-4 right-4 text-xl text-red-600" onClick={handleClosePopup}>X</button>
                        <div className="w-full h-full border-2 border-gray-500 border-dashed rounded-xl">
                            <div className="flex justify-center items-center h-full">
                            <input
                            className="w-full border hidden border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
                            type="file"
                            placeholder="hero image"
                            name={uploadType === "profileBackgroundImage" ? "cover_img" : "hero_img"}
                            onChange={(e) => {
                                const file = e.target.files[0];
                                const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                        
                                if (file && file.size > maxSize) {
                                    alert("File size exceeds 5 MB. Please upload a smaller image.");
                                    e.target.value = null; // Clear the input field
                                    return;
                                }
                        
                                const img = new Image();
                                img.src = URL.createObjectURL(file);
                        
                                img.onload = () => {
                                    const width = img.width;
                                    const height = img.height;
                        
                                    if (uploadType === "profileBackgroundImage") {
                                        if (width < 1500 || height < 400) {
                                            alert("The cover image size must be more than 1500*400 pixels.");
                                            e.target.value = null; // Clear the input field
                                        } else {
                                            setImage(e);
                                            setSelectedImage(file);
                                        }
                                    } else {
                                        // For "hero_img", you can set specific size constraints or skip this check.
                                       
                                          if (width < 100 || width > 400 || height < 100 || height > 400) {
                                            alert("The profile image size must be  between 100x100 pixels and 400x400 pixels.");
                                            e.target.value = null; // Clear the input field
                                        } else {
                                            setImage(e);
                                            setSelectedImage(file);
                                        }
                                    }
                                };
                            }}
                            id="bgImage"
                        />
                        
                                <label htmlFor="bgImage" className="cursor-pointer">
                                    <IoCloudUploadOutline className="h-48 w-48 text-gray-500 sm:w-80 sm:h-80" />
                                </label>
                            </div>
                        </div>
                        <div className="flex justify-between items-center mt-4">
                            <p className="text-gray-500">{text}</p>
                            <label htmlFor="bgImage" className="cursor-pointer">
                                <input type="file" id="bgImage" className="hidden" />


                                {imgLoading && <button
                                    className="text-green-700 px-12 py-2 rounded-3xl"
                                    htmlFor="bgImage"
                                    disabled={loading || !selectedImage}
                                >
                                    Uploading . . .
                                </button>}

                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageUpload;
