import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiurl } from "../config/config";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getCookie } from "../config/coockies";
import Layout from "../components/layout";
import { IoLocationOutline, IoArrowBackSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import LoaderSpiner from "../components/loader";
import { FaComment, FaShare, FaCheck, FaThumbsUp } from "react-icons/fa";
import { GetTimeAgo } from "../components/getTime";
import { useUser } from "../contexts/UserProvider";

const UserProfile = () => {
  const token = getCookie("savage_token");
  const { userid } = useParams();
  const { userData } = useUser();
  const [profileData, setProfileData] = useState({});
  const [isFollow, setIsFollow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [followerTab, setFollowerTab] = useState("allPost");
  const [getAllFollower, SetGetAllFollower] = useState([]);
  const [getAllFollowing, SetGetAllFollowing] = useState([]);
  const [getGetAllMutual,SetGetAllMutual]=useState([])
  const [isBlockedByme, setIsBlockedByme]=useState(false)
  const [isBlockedme, setIsBlockedme]=useState(false)
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleUserProfile = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiurl}/user/profile/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      console.log("user profile data >>>>>", response);
      if (response.data.success === true) {
        setLoading(false);
        setProfileData(response.data);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("User Profile error", error);
    }
  };

  useEffect(() => {
    handleUserProfile();
  }, [userid]);

  const isVideoExtension = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    const videoExtensionsChange = videoExtensions.toString();
    const extension = getExtension(url);
    const videoData = videoExtensionsChange.includes(extension)
      ? "video"
      : imageExtensions.includes(extension)
      ? "image"
      : "unknown";
    return videoData;
  };
  const getExtension = (url) => {
    if (!url) return "";
    const splitUrl = url.split(".");
    if (
      splitUrl.length === 1 ||
      (splitUrl[0] === "" && splitUrl.length === 2)
    ) {
      return "";
    }
    return splitUrl.pop().toLowerCase();
  };

  useEffect(() => {
    handleCheckFollowing();
  }, [userid]);

  const handleSendFollowRequest = async () => {
    console.log(">>>>89",profileData)
    setIsFollow(true);
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/send-request`,
        { senderId: userData._id, receiverId: userid, userType:profileData?.user?.userType },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('send follow request response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        if(response.data.friendRequest)
        {
          setIsRequest(true);
          setIsFollow(false);
        }
        else{
          setIsRequest(false);
          setIsFollow(true);
        }
        
        handleUserProfile();
        toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("send follow request", error);
    }
  };

  const handleCheckFollowing = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiurl}/follow/check-following-user/${userid}/${userData._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('follower check response >>>>>>>', response)
      if (response.data.status === "accepted") {
        setLoading(false);
        setIsFollow(true);
        setIsRequest(false);
      } else if (response.data.status === "pending") {
        setIsRequest(true);
        setLoading(false);
      } else {
        setIsRequest(false);
        setIsFollow(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("all following", error);
    }
  };

  const handleRemoveFollower = async () => {
    setIsFollow(false);
    try {
      setLoading(true);
      const data = {
        userId: userid,
        friendId: userData._id,
        isRequest:isRequest
      };

      const response = await axios.delete(`${apiurl}/follow/unfriend`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      });
      if (response.data.success === true) {
        setLoading(false);
        handleCheckFollowing();
        handleUserProfile();
        toast.success(response.data.message);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("remove-request", error);
    }
  };

  const handleGetFollowers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiurl}/follow/followers/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log('myfollowers response ------- >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        SetGetAllFollower(response?.data?.follower);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("all follower", error);
    }
  };
  const handleGetMutual = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiurl}/follow/get-mutual-friend/${userData._id}/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log('myfollowers response ------- >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        SetGetAllMutual(response?.data?.mutualFriends);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("all follower", error);
    }
  };

  const handleGetFollowing = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiurl}/follow/following/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      // console.log('myfollowing response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        SetGetAllFollowing(response?.data?.following);
        // toast.success(response.data.message)
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("all following", error);
    }
  };
  const handleblockRequest = async (receiverId) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/black-list`,
        { userId: userData._id, friendId: receiverId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('send follow request response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        setIsBlockedByme(true)
        toast.success(response.data.message);
       
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("send follow request", error);
    }
  };
  const handleunblockRequest = async (receiverId) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/un-black-list`,
        { userId: userData._id, friendId: receiverId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('send follow request response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        setIsBlockedByme(false)
        toast.success(response.data.message);
       
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("send follow request", error);
    }
  };
  const handleBlockstatus = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/getblockstatus`,
        { userId: userData._id, friendId: userid },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('send follow request response >>>>>>>', response)
      if (response.data.success === true) {
        setLoading(false);
        setIsBlockedByme(response.data.isBlockedByMe)
        setIsBlockedme(response.data.isBlockedMe)
       
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("send follow request", error);
    }
  };
  useEffect(() => {
    handleGetFollowers();
    handleGetFollowing();
    handleGetMutual();
    handleBlockstatus();
  }, [userid]);

  return (
    <div>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <Layout>
        <div className="dark:bg-gray-700 bg-gray-200 p-4">
        <div className="flex">
        <Link
        className="flex items-center text-red-600 border border-red-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-red-600 hover:text-white transition-all duration-500 ease-in-out transform"
        to="/communities"
      >
        <IoArrowBackSharp className="mr-2" /> Back to Group Hub
      </Link>
              <button
                className="flex items-center ml-2 text-green-600 border border-green-600 px-2 py-1 rounded-lg sm:px-5 sm:py-2 hover:bg-green-600 hover:text-white transition-all duration-500 ease-in-out transform"
                onClick={handleGoBack}
              >
                <IoArrowBackSharp className="mr-2" /> Back
              </button>

        </div>
          <div className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border sm:-mt-8 mt-2 bg-cover bg-no-repeat bg-center  bg-[#302b27] bg-[url('/public/assets/blue.jpg')]">
            {profileData && (
              <div className=" pb-6 w-[95%] sm:w-11/12 m-auto">
                <div className="flex justify-between items-center mt-4">
                  <div className="w-1/3">
                  
                    <img
                      className="sm:h-40 sm:w-40 h-20 w-20 p-[2px] rounded-full border-2 border-gray-400 dark:border-gray-800 mx-auto mt-4"
                      src={profileData.user?.hero_img}
                      alt="profile image"
                    />

                    <div className="py-2  m-auto">
                      <h3 className="font-bold text-center capitalize sm:text-2xl text-sm text-white dark:text-white sm:mb-1 mb-0">
                        {/*{profileData.user?.name}*/}
                        {profileData.user?.userHandle}
                        
                      </h3>
                      <p className="text-center text-white">{profileData.user?.bio}</p>
                      {profileData.user?.location ? (
                        <div>
                          <h3 className="flex text-center items-center justify-center text-[#fff] dark:text-gray-300">
                            <IoLocationOutline />
                            {profileData.user?.location}
                          </h3>
                        </div>
                      ) : null}
                    </div>
                    {isBlockedme?"":
                    <div className="sm:px-0 flex sm:w-[400px] w-[200px]" >
                      {isFollow ? (
                        
                          <button
                            className="w-[150px] flex-1 rounded-full bg-blue-600 dark:bg-blue-800 text-white antialiased font-semibold hover:bg-blue-800 dark:hover:bg-blue-900 py-1 transition-all sm:text-lg text-xs duration-500"
                            onClick={handleRemoveFollower}
                          >
                            Unfollow
                          </button>
                          
                        
                      
                      ) 
                      : (
                        <button
                          className="w-full flex-1 rounded-full bg-blue-600 dark:bg-blue-800 text-white antialiased font-semibold hover:bg-blue-800 dark:hover:bg-blue-900 px-4 py-2 transition-all sm:text-lg text-xs duration-500"
                          onClick={isRequest?handleRemoveFollower:handleSendFollowRequest}
                        >
                          {isRequest ? "Request" : "Follow"}
                        </button>
                      )}
                      {isBlockedByme?
                        <button
                        className="w-[150px] ml-2 flex-1 rounded-full bg-red-600 text-white antialiased font-semibold hover:bg-red-800 dark:hover:bg-red-900 py-1 transition-all sm:text-lg text-xs duration-500"
                        onClick={() =>
                          handleunblockRequest(profileData.user?._id)
                        }
                        >
                        UnBlock</button>:
                        isBlockedme?
                        <button
                        className="w-[150px] ml-2 flex-1 rounded-full bg-red-100 text-white antialiased font-semibold hover:bg-red-800 dark:hover:bg-red-900 py-1 transition-all sm:text-lg text-xs duration-500"
                        
                        >
                        Blocked</button>
                        :
                      <button
                      className="w-[150px] ml-2 flex-1 rounded-full bg-blue-600 dark:bg-blue-800 text-white antialiased font-semibold hover:bg-blue-800 dark:hover:bg-blue-900 py-1 transition-all sm:text-lg text-xs duration-500"
                      onClick={() =>
                        handleblockRequest(profileData.user?._id)
                      }
                    >
                      Block</button>
}
                    </div>}
                    

                  </div>
                  
                  <div className="flex justify-between items-center w-7/12 sm:-mt-20 -mt-12">
                    <div
                      className={`flex flex-col justify-center items-center cursor-pointer ${
                        followerTab === "allPost"
                          ? "text-blue-600"
                          : "text-[#fff]"
                      }`}
                      onClick={() => setFollowerTab("allPost")}
                    >
                      <h2 className="sm:text-xl text-sm font-semibold">
                        {" "}
                        {profileData?.postCount}
                      </h2>
                      <h2 className="sm:text-xl text-sm font-semibold ">
                        {" "}
                        Post
                      </h2>
                    </div>
                    <div
                      className={`flex flex-col justify-center items-center cursor-pointer ${
                        followerTab === "myfollowers"
                          ? "text-blue-600"
                          : "text-[#fff]"
                      }`}
                      onClick={() => setFollowerTab("myfollowers")}
                    >
                      <h2 className="sm:text-xl text-sm font-semibold">
                        {" "}
                        {profileData?.followersCount}
                      </h2>
                      <h2 className="sm:text-xl text-sm font-semibold">
                        {" "}
                        Followers
                      </h2>
                    </div>
                    <div
                      className={`flex flex-col justify-center items-center cursor-pointer ${
                        followerTab === "myfollowing"
                          ? "text-blue-600"
                          : "text-[#fff]"
                      }`}
                      onClick={() => setFollowerTab("myfollowing")}
                    >
                      <h2 className="sm:text-xl text-sm font-semibold">
                        {profileData?.followingCount}
                      </h2>
                      <h2 className="sm:text-xl text-sm font-semibold">
                        {" "}
                        Following
                      </h2>
                    </div>
                  </div>
                </div>
                {isBlockedme?"":
                <p className="w-full text-white">
                      {getGetAllMutual.map((item, index) => {
                        const isLast = index === getGetAllMutual.length - 1;
                        const separator = isLast ? " and " : ", ";
                        return (
                        <Link to={`/profile/${item.userId}`}>
                          <span key={item.id} className="capitalize">
                            {item.username}
                            {isLast ? "" : separator}
                          </span>
                          </Link>
                        );
                      })}
                      {getGetAllMutual.length > 1 && " are mutual friends."}
                      {getGetAllMutual.length === 1 && " is a mutual friend."}
                    </p>
                    }
                <div className="flex flex-col justify-center"></div>
              </div>
            )}
          </div>
          {profileData?.user?.userType=="public" || isFollow?
          followerTab === "allPost"  && (
            isBlockedme?"":
            <div className="flex flex-col">
              {profileData.posts && profileData.posts.length > 0 ? (
                profileData.posts
                  .slice()
                  .reverse()
                  .map((post, index) => (
                    <div
                      key={index}
                      className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border "
                    >
                    <Link to={`/profile/${post?.createdby?._id}`}>
                      <div className="p-4 flex flex-row items-center">
                        <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                          {post?.createdby?.username.charAt(0).toUpperCase()}
                        </p>
                        <div className="lg:w-full relative pl-2">
                          <div className="text-[16px] lg:text-[16px] font-bold  flex items-center  cursor-pointer">
                            <h2 className="text-lg font-semibold">
                              {post?.createdby?.username}
                            </h2>
                            <FaCheck className="p-1 m-1 bg-[#0866FF] text-white rounded-[100%] w-3 h-3 lg:w-[15px] lg:h-[15px]" />
                          </div>
                          <div>
                            <p className="text-gray-500 text-xs">
                              {GetTimeAgo(post.createdAt)}
                            </p>
                          </div>
                        </div>
                      </div>
                      </Link>
                      <Link to={`/community/${post?.post_handle}/posts/${post?._id}`}>
                      <div className="relative m-auto mx-3">
                        <h2 className="text-xl my-5  font-bold  flex items-center  cursor-pointer">
                          {post?.title}
                        </h2>
                        <p className="text-base leading-8 border-b-[1px] pb-5">
                          {post?.content}
                        </p>

                        {post && (
                          <div className="relative w-[100%]  full  m-auto ">
                            <div className="rounded-xl py-4">
                              {post.images && post.images[0]?.url && (
                                <div className="rounded-xl ">
                                  {isVideoExtension(post.images[0].url) ===
                                  "video" ? (
                                    <video
                                      className="w-auto h-auto  rounded-lg relative m-auto"
                                      controls
                                      autoPlay
                                      loop
                                      muted
                                    >
                                      <source
                                        src={post.images[0].url}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <img
                                      src={post.images[0].url}
                                      alt="post image"
                                      className="w-auto h-auto rounded-lg relative m-auto "
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      </Link>
                      {post?.buyNowUrl && (
                        <div className="flex items-center justify-center my-4">
                          <Link
                            to={post?.buyNowUrl}
                            target="_blank"
                            className="bg-black text-white px-6 p-2 rounded font-medium text-lg hover:bg-red-700 transition-all duration-500"
                          >
                            {" "}
                            Buy Now{" "}
                          </Link>
                        </div>
                      )}
                      <Link to={`/community/${post?.post_handle}/posts/${post?._id}`}>
                      <div className="flex w-full justify-between h-[50px] p-5 border-b border-t border-[#9e9e9e]">
                        <p className="flex items-center cursor-pointer">
                          <>
                            <FaThumbsUp className="text-[#1F51FF]" />
                            <span className="pl-2 text-[#1F51FF]">
                              Like ({post?.likes?.likedBy?.length})
                            </span>
                          </>
                        </p>
                        <p className="flex items-center cursor-pointer">
                          <FaComment className="text-[#9e9e9e]" />
                          <span className="pl-2">
                            Comment ({post?.comments?.length})
                          </span>
                        </p>
                        <p className="flex items-center cursor-pointer">
                          <FaShare className="text-[#9e9e9e]" />
                          <span className="pl-2">Share</span>
                        </p>
                      </div>

                      <div className="flex flex-col p-2 justify-center items-start">
                        {post?.comments &&
                          post?.comments.map((comment, index) => {
                            return (
                              <div
                                className="mt-4 rounded-lg border relative p-2 w-full bg-[#fafafa]"
                                key={index}
                              >
                                <div className="px-3 rounded-md">
                                  <div className="flex items-center mb-4">
                                    <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                                      {comment?.author?.username
                                        ?.charAt(0)
                                        .toUpperCase()}
                                    </p>
                                    <div>
                                      <h3 className="text-md font-semibold">
                                        {comment?.author?.username}
                                      </h3>
                                      <p className="text-gray-500 text-xs">
                                        {GetTimeAgo(comment.createdAt)}
                                      </p>
                                    </div>
                                  </div>
                                  <p>{comment?.text}</p>
                                </div>

                                <button className="text-[#005AE6] font-semibold mx-3 my-3 hover:underline cursor-pointer">
                                  Reply
                                </button>

                                {comment?.replies.map((reply, index) => {
                                  return (
                                    <div
                                      className="border-t hover:bg-gray-100 bg-gray-50  p-3 relative ml-8 w-auto"
                                      key={index}
                                    >
                                      <div className="flex items-center mb-4">
                                        <p className="w-10 h-10 flex items-center justify-center rounded-full mr-2 border border-gray-500 p-1 font-bold text-uppercase bg-slate-300">
                                          {reply?.author?.username
                                            ?.charAt(0)
                                            .toUpperCase()}
                                        </p>
                                        <div>
                                          <h3 className="text-md font-semibold">
                                            {reply?.author?.username}
                                          </h3>
                                          <p className="text-gray-500 text-xs">
                                            {GetTimeAgo(reply.createdAt)}
                                          </p>
                                        </div>
                                      </div>
                                      <p> {reply?.text}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })}
                      </div>
                      </Link>
                    </div>
                    
                  ))
              ) : (
                <div className="bg-white p-8 rounded-lg shadow-md mb-4">
                  <p className="text-2xl font-semibold mb-4 text-center">
                    No post is available
                  </p>
                </div>
              )}
            </div>
          ):""}

          {followerTab === "myfollowers" ? (
          isBlockedme?"":
            <div className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border mt-4">
              <div className="border-b w-full m-auto">
                {getAllFollower && getAllFollower.length > 0 ? (
                  <div className="mx-auto">
                    {getAllFollower.map((follower, index) => (
                      <Link to={`/profile/${follower.userId}`}>
                        <div className="sm:p-5 py-3 flex items-center justify-between cursor-pointer hover:bg-gray-200 transition-all duration-500">
                          <Link to={`/profile/${follower.userId}`}>
                            <div className="flex items-center w-full sm:w-20 sm:h-16 ml-3 sm:ml-0 py-3">
                              <img
                                className="rounded-full h-20 sm:w-20 w-[6.5rem]"
                                src={follower.hero_img}
                                alt="follower image"
                              />
                            </div>
                          </Link>
                          <div className="flex  sm:flex-row sm:justify-between sm:items-center w-full flex-col items-start ml-6 ">
                            <Link to={`/profile/${follower.userId}`}>
                              <div className="ml-2 flex flex-col">
                                <div className="leading-snug sm:text-lg text-sm text-gray-900 font-semibold mb-1 capitalize">
                                  {follower.username}
                                </div>
                                <div className="leading-snug sm:text-sm text-xs text-gray-600">
                                  {follower.email}
                                </div>
                              </div>
                            </Link>
                            {/* <div className="flex justify-between items-center sm:mt-0 mt-2">
                                                            <button className="sm:py-2 py-[2px] px-5  sm:text-md text-xs sm:font-semibold font-normal bg-red-100 mr-2 text-red-500 border border-red-600 rounded-full  hover:bg-red-600 hover:text-white transition-all duration-300"
                                                                onClick={() => handleRemoveFollower(follower.userId)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div> */}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className="flex justify-center items-center my-10">
                    <p className="sm:text-3xl text-xl font-semibold">
                      No Follower available
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {followerTab === "myfollowing" ? (
          isBlockedme?"":
            <div className="sm:w-11/12 md:w-3/4 lg:w-1/2 my-2 sm:mx-auto p-2 bg-white rounded-xl shadow-lg overflow-hidden border mt-4">
              <div className="border-b w-full m-auto">
                {getAllFollowing && getAllFollowing.length > 0 ? (
                  <div className="mx-auto">
                    {getAllFollowing.map((following, index) => (
                      <Link to={`/profile/${following.userId}`}>
                        <div className="sm:p-5 py-3 flex items-center justify-between cursor-pointer hover:bg-gray-200 transition-all duration-500">
                          <Link to={`/profile/${following.userId}`}>
                            <div className="flex items-center w-full sm:w-20 sm:h-16 ml-3 sm:ml-0 py-3">
                              <img
                                className="rounded-full h-20 sm:w-20 w-[6.5rem]"
                                src={following.hero_img}
                                alt="follower image"
                              />
                            </div>
                          </Link>
                          <div className="flex  sm:flex-row sm:justify-between sm:items-center w-full flex-col items-start ml-6 ">
                            <Link to={`/profile/${following.userId}`}>
                              <div className="ml-2 flex flex-col">
                                <div className="leading-snug sm:text-lg text-sm text-gray-900 font-semibold mb-1 capitalize">
                                  {following.username}
                                </div>
                                <div className="leading-snug sm:text-sm text-xs text-gray-600">
                                  {following.email}
                                </div>
                              </div>
                            </Link>
                            {/* <div className="flex justify-between items-center sm:mt-0 mt-2">
                                                            <button className="sm:py-2 py-[2px] px-5  sm:text-md text-xs sm:font-semibold font-normal bg-red-100 mr-2 text-red-500 border border-red-600 rounded-full  hover:bg-red-600 hover:text-white transition-all duration-300"
                                                                onClick={() => handleRemoveFollower(following.userId)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div> */}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className="flex justify-center items-center my-10">
                    <p className="sm:text-3xl text-xl font-semibold">
                      No Following available
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </Layout>
    </div>
  );
};

export default UserProfile;
