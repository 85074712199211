import React, { useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
const MarketplaceTerms = () => {

  return (
    <>
    <Header />
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <h1 className='text-xl font-[600] text-center  underline mb-5 p-2'>Welcome to Savage Seller!</h1>
      
      <section>
    
        <p>
        These terms and conditions ("Terms") govern your use of our website and services, including any features, functionalities, and content provided through the website and associated mobile applications (collectively, the "Platform"). By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you must not use the Platform.

        </p>

        <h1 className='text-xl font-[600]  mt-2'>1. Privacy Policy</h1>
        <p>Your privacy is important to us. We do not sell, rent, or lease your personal information to third parties for marketing purposes. Any personal data collected from you is used solely for the purposes outlined in our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and protect your information.</p>

        <h1 className='text-xl font-[600]  mt-2'>2. Description of Services</h1>
        <p> Savage Seller is a marketplace platform that enables users to buy, sell, and rent goods, offering a group chat feature to foster community interaction. The Platform facilitates peer-to-peer transactions but does not own or sell any products directly. Savage Seller provides tools for listing, searching, and completing transactions but does not control or guarantee the quality, safety, legality, or accuracy of the items listed.</p>

        <h1 className='text-xl font-[600] mt-2'>3.  Eligibility</h1>
        <p>To use Savage Seller, you must be at least 13 years old. By using the Platform, you represent and warrant that you meet these eligibility requirements. If you are under 18 years old, you may only use the Platform under the supervision of a parent or legal guardian.</p>

        <h1 className='text-xl font-[600] mt-2'>4.  Account Registration and Security</h1>
        <p>You may be required to create an account to access certain features of the Platform. You agree to provide accurate and current information during registration and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and must notify us immediately if you suspect any unauthorized access to your account.
        </p>
        <p>Savage Seller reserves the right to suspend or terminate your account at our discretion for violations of these Terms.</p>

        <h1 className='text-xl font-[600]  mt-2'>5. User Conduct</h1>
        <p> You agree to use the Platform only for lawful purposes and in compliance with all applicable laws and regulations. You further agree not to:
        </p>
        <ul className='list-disc pl-8'>
          <li>Violate any laws or third-party rights.</li>
          <li>Post false, misleading, or deceptive content.</li>
          <li>Use the Platform to harass, abuse, or harm others.</li>
          <li>Distribute viruses or any other technologies that may harm the Platform.</li>
          <li>Attempt to gain unauthorized access to the Platform or its systems.</li>
        </ul>

        <h1 className='text-xl font-[600]  mt-2'>6. Marketplace Terms</h1>
        <ul className='list-disc pl-8'>
          <li>Savage Seller acts solely as an intermediary between buyers and sellers. We are not responsible for any disputes, claims, or liabilities arising from transactions conducted on the Platform. </li>
          <li>Users are responsible for the legality, accuracy, and appropriateness of their listings. Savage Seller reserves the right to remove any listings that violate these Terms, our policies, or applicable laws.</li>
          <li>Savage Seller is not responsible for any losses or damages incurred through transactions on the Platform.</li>
        </ul>

        <h1 className='text-xl font-[600]  mt-2'>7. Group Chat and Community Features</h1>
        <p> Savage Seller provides a group chat feature where users can communicate and engage with others. By participating, you agree to:
        </p>
        <ul className='list-disc pl-8'>
          <li>Treat others with respect and refrain from offensive, harassing, or inappropriate behavior.</li>
          <li>Not post personal information (such as phone numbers or addresses).</li>
          <li>Not use the chat for spamming, advertising, or solicitation.</li>
        </ul>
        <p>We reserve the right to monitor and moderate the group chat and remove any content or users that violate these Terms or our community standards.</p>

        <h1 className='text-xl font-[600]  mt-2'>8.  Intellectual Property</h1>
        <p>
        All content on the Platform, including but not limited to text, graphics, logos, and images, is the property of Savage Seller or its licensors and is protected by intellectual property laws. You may not use, reproduce, modify, or distribute any content from the Platform without written permission from us.

        </p>
        <h1 className='text-xl font-[600] mt-2'>9. Limitation of Liability</h1>
        <ul className='list-disc pl-8'>
          <li>Savage Seller disclaims all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.
          </li>
          <li>We are not liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of the Platform, even if we have been advised of the possibility of such damages.</li>
          <li>Our total liability to you for any claims arising from your use of the Platform will not exceed the amount you paid (if any) to use our services in the six months preceding the incident giving rise to the claim.</li>
        </ul>
        <h1 className='text-xl font-[600]  mt-2'>10. Dispute Resolution</h1>
        <p> In the event of a dispute between users, we encourage open communication and collaboration to reach an amicable resolution. If direct resolution fails, Savage Seller may provide mediation services to help facilitate an agreement.
        </p>
        <p>All disputes between you and Savage Seller arising out of or related to these Terms or your use of the Platform shall be governed by the laws of the United States, without regard to its conflict of laws principles.</p>
        <h1 className='text-xl font-[600] mt-2'>11. Modifications to Term</h1>
        <p>We reserve the right to modify these Terms at any time. Any changes will become effective upon posting the updated Terms on the Platform. Your continued use of the Platform following the posting of changes constitutes your acceptance of those changes.

        </p>
        <h1 className='text-xl font-[600]  mt-2'>12.  Termination</h1>
        <p> Savage Seller may terminate or suspend your account and access to the Platform at any time for any reason, including for violations of these Terms or for any behavior that we deem harmful or inappropriate. Upon termination, you will no longer have access to your account or the Platform.
        </p>
        <h1 className='text-xl font-[600]  mt-2'>12.   Contact Information </h1>
        <p className='mb-3'>  If you have any questions or concerns about these Terms, please contact us at <strong>sav@savageseller.com.</strong>
        </p>
        <hr />
        <h1 className='font-bold mt-3 text-center'>By using Savage Seller, you agree to these Terms. Thank you for being part of our community!</h1>
      </section>

      

      
    </div>
    <Footer />
    </>
  );
};

export default MarketplaceTerms;
