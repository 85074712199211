import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaRegUserCircle, FaAngleDown } from "react-icons/fa";
import { getCookie, setCookie, removeCookie } from "../config/coockies";
import { apiurl, marketplaceapiurl } from "../config/config.js";
import axios from "axios";
import { useUser } from "../contexts/UserProvider.js";
import Notification from "./notifcation";
import { LiaStoreAltSolid } from "react-icons/lia";
import { jwtDecode } from "jwt-decode";
import { IoNotificationsOutline } from "react-icons/io5";
export default function Header() {
  const { userData } = useUser();
  const [activeLink, setActiveLink] = useState("");
  const [user, setuser] = useState({});
  const [navbar, setNavbar] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchPopup, setSearchPopup] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [isseller, setIsseller] = useState(false);
  let token = getCookie("savage_token");
  let decodedToken;
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {}, []);
  const [getUser, setGetUser] = useState(false);

  useEffect(() => {
    const token = getCookie("savage_token");
    if (token) {
      setGetUser(true);
    }
    if (!token) {
      setGetUser(false);
    }
  }, [getUser]);

  if (token) {
    decodedToken = jwtDecode(token);
    // console.log(decodedToken.user.email);
  }
  const handleUniversalSearch = async () => {
    try {
      const response = await axios.get(`${apiurl}/search?by=${searchQuery}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      setSearchData(response.data?.data);
      setCommunities(response.data?.data?.communities || []);
      setPosts(response.data?.data?.posts || []);
      setUsers(response.data?.data?.users || []);
      // console.log('universal search response', response)
    } catch (error) {
      console.error("Universal search error:", error);
    }
  };

  // console.log('get communities data', communities)
  // console.log('get posts data', posts)
  // console.log('get users data', users)
  // console.log('searchData', searchData)
  // console.log('searchQuery value', searchQuery)

  // const validateToken = async (token) => {
  //   try {
  //     const response = await axios.post(
  //       `${apiurl}/user/verifyuser`,
  //       {},
  //       {
  //         headers: {
  //           Authorization: token,
  //         },
  //       }
  //     );
  //     if (response.data?.success === true) {
  //       console.log("response.data.user", response.data.user);
  //       setuser(response.data.user);
  //     } else {
  //       <Navigate to="/" />
  //       // throw new Error("Token validation failed - Response not successful");

  //     }
  //   } catch (error) {
  //     console.error("Token validation error:", error);
  //     // throw new Error("Token validation failed");
  //     <Navigate to="/" />
  //   }
  // };

  // useEffect(() => {
  //   validateToken(token);
  // }, [token]);

  const checkVendor = async () => {
    try {
      const { data } = await axios({
        url: `${marketplaceapiurl}/seller/checksellerexistance`,
        method: "POST",
        data: {
          email: decodedToken.user.email,
        },
      });
      // console.log("role listing", data);

      if (data?.data) {
        if (typeof data?.data === "string") {
          setIsseller(false);
          const isSeller = data?.data;
          const expirationTime = 50 * 60;
          setCookie("is_seller", isSeller);
        } else {
          if (data?.data?.paid === true || data?.data?.sellerType=="individual" || data?.data?.sellerType=="organization") {
            setIsseller(true);
            const isSeller = data?.data;
            const expirationTime = 50 * 60;
            setCookie("is_seller", true);
            removeCookie("seller_id");
          } else {
            setIsseller(false);
            setCookie("seller_id", data?.data?.sellerId);
            removeCookie("is_seller");
          }
        }
      } else {
        setIsseller(false);
        removeCookie("is_seller");
        removeCookie("seller_id");
      }
    } catch (error) {
      removeCookie("is_seller");
      removeCookie("seller_id");
    }
  };

  useEffect(() => {
    checkVendor();

    const handleResize = () => {
      const windowSize = window.innerWidth;
      if (windowSize > 425) {
        setNavbar(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log(searchQuery.length, searchData)

  return (
    <div className="w-full bg-white shadow ">
      <div className="flex items-center justify-between sm:px-5 px-1 relative sm:my-0 w-full">
        <div className="flex w-1/3">
          <Link
            to={`https://savageseller.com/`}
            className="sm:px-4 px-2 py-2  transition-all duration-300 hover:translate-x-1"
          >
            <img
              src="/assets/savageseller-logo.jpg"
              alt="logo"
              className="lg:w-1/2 md:w-3/4 sm:w-1/2 w-full h-auto rounded-full"
            />
          </Link>
        </div>
        <div className="justify-end sm:items-center sm:flex relative w-[90%] flex">
          {userData?.firstname && (
            <Link
              to="https://savageseller.com/"
              className="flex items-center justify-center w-auto"
            >
              <div className="flex justify-center w-8 items-center">
                <LiaStoreAltSolid className="sm:hidden block w-7 h-7 absolute right-16" />
              </div>
            </Link>
          )}
          {!userData?.firstname && !getUser ? (
            <div className="flex justify-between items-center">
              <li className="flex items-center sm:pr-2 pr-0 relative">
                <Link
                  to="/"
                  className=" inline-flex items-center justify-center py-3 overflow-hidden font-medium transition duration-300 ease-out group w-auto   sm:mr-2 mr-0"
                >
                  <button className=" font-semibold text-md sm:block hidden ">
                    Group hub
                  </button>
                  <div className="flex justify-end w-8">
                    <LiaStoreAltSolid className="sm:hidden block w-7 h-7 absolute right-[0px] top-0" />
                  </div>
                </Link>
              </li>

              <li className="flex gap-2 justify-end items-center p-1 lg:p-2 h-[40px] text-black">
                <Link to="/register">
                  <button className="flex items-center justify-center font-bold bg-[#E0E9F7] sm:w-24 w-16 px-1 py-2 text-[#005AE6]  border border-[#005AE6] rounded-lg sm:text-base text-xs hidden sm:block">
                    Sign Up
                  </button>
                </Link>
              </li>
              <li className="flex gap-2 justify-end items-center lg:p-2 h-[40px] text-black mx-2">
                <Link to="/login">
                  <button className="flex items-center justify-center font-bold bg-[#005AE6] sm:w-24 w-16 px-1 py-2 text-[#fff] rounded-lg sm:text-base text-xs hidden sm:block">
                    Login
                  </button>
                  <button className="sm:hidden block">
                    <FaRegUserCircle className="h-7 w-7" />
                  </button>
                </Link>
              </li>
            </div>
          ) : (
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between py-1 sm:py-3 md:py-0 md:block mr-0 sm:mr-6">
                <div className="flex justify-between flex-row-reverse w-full">
                  <div className="md:hidden ml-4">
                    <button
                      className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                      onClick={() => setNavbar(!navbar)}
                    >
                      {navbar ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-black"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-black"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`flex-1 justify-self-center pb-3 md:block md:pb-0 md:mt-0 ${
                  navbar
                    ? "block absolute top-12 sm:py-4 bg-white min-h-screen z-50 right-0 sm:hidden w-[380px]"
                    : "hidden"
                }`}
              >
                <ul className="items-center justify-end space-y-8 md:flex md:space-x-6 md:space-y-0 px-4">
                  <div
                    className={`flex-col-reverse flex sm:flex-row justify-between w-full  ${
                      navbar ? "block mt-5 items-start" : "items-center"
                    }`}
                  >
                     {/*<li className={navbar ? "block mb-2" : ""}>
                      <button className="flex justify-between items-center font-semibold text-lg sm:mr-4 sm:ml-2 mr-0 ml-0" onClick={() => {
                        setIsNotification(!isNotification);
                      }}>
                        <IoNotificationsOutline className="text-[50px] border border-gray-300 p-3.5 rounded-full " />
                        <span className={navbar ? "block pl-3" : "hidden"}> Notification</span>
                      </button>
                    </li> */}

                    <div className="sm:flex sm:justify-between justify-center flex-column items-center sm:my-0 my-2 sm:pb-0 pb-4">
                      <li className="flex items-center justify-start sm:pr-2 pr-0 w-full sm:w-[160px]">
                        <Link
                          to="/"
                          className=" inline-flex items-center justify-center py-3 overflow-hidden font-medium transition duration-300 ease-out group sm:mr-2 mr-0"
                        >
                          <button className=" font-semibold text-md text-[#5c5c5c]">
                          Group hub
                          </button>
                        </Link>
                      </li>
                      {window.location.href!="https://community.savageseller.com/"?

                      !isseller ? (
                        <div className="relative flex items-center sm:pl-5 pl-0 ">
                          <div className="group flex items-center">
                            <Link
                              to=""
                              className="relative inline-flex items-center justify-start py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out group sm:w-50 w-56   sm:mr-2 mr-0"
                            >
                              <span className="absolute flex items-center justify-center py-3 text-[#5c5c5c]">
                              Become a Seller
                                <FaAngleDown className="ml-2" />
                              </span>
                            </Link>
                            <div className="absolute left-0 w-52 bg-white border border-gray-200 rounded-md shadow-lg opacity-0 group-hover:opacity-100  mt-[180px]">
                              <div className="py-1 z-20 relative bg-gray-200 border rounded-md shadow-sm text-center">
                                <Link
                                  to="/businessRegister"
                                  className="block px-3 py-2 text-gray-800 hover:text-blue-700 border-b-2"
                                >
                                  Business Seller
                                </Link>
                                <Link
                                  to="/vendorRegister"
                                  className="block px-3 py-2 text-gray-800 hover:text-blue-700"
                                >
                                  Individual Seller
                                </Link>
                                <Link
                                  to="/organizationRegister"
                                  className="block px-3 py-2 text-gray-800 hover:text-blue-700"
                                >
                                Organizations
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      ):""}
                    </div>

                    {getUser && (
                      <>
                        <div className="sm:mx-4 mx-0 sm:mt-0 mt-3">
                          <div className="relative flex items-center justify-center bg-[#EFF5FF] rounded-md md:w-[200px] w-full md:flex px-3 py-0">
                            <input
                              type="search"
                              className="py-3 px-4 w-full outline-none bg-[#EFF5FF] rounded-md"
                              placeholder="Search "
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              onKeyUp={handleUniversalSearch}
                              onClick={() => setSearchPopup(true)}
                              
                            />
                            <button className="px-2 focus:outline-none">
                              <FaSearch className="h-4 w-4 text-gray-600" />
                            </button>
                            {searchQuery.length > 0 && searchData && (
                              <>
                                {communities?.length > 0 ||
                                posts?.length > 0 ||
                                users?.length > 0 ? (
                                  <div className="flex justify-center items-center  overflow-y-auto fixed inset-0 z-50 outline-none  focus:outline-none bg-[#000000b5]">
                                    <div className="absolute top-14 my-6 mx-auto w-11/12 sm:w-2/5 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-fit overflow-y-scroll">
                                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        <div className="flex justify-end mr-2 p-3 border-b border-solid border-gray-300 rounded-t ">
                                          <button
                                            className="bg-transparent border-0 text-black text-3xl"
                                            onClick={() => setSearchData(false)}
                                          >
                                            {/* <p>{searchData.length >0 ? 'NotFound Data' : null}</p> */}

                                            <span>x</span>
                                          </button>
                                        </div>
                                        <div className="px-4 py-2">
                                          <h3 className="text-xl font=semibold mb-2 w-full">
                                            {communities?.length > 0
                                              ? "Communities"
                                              : null}
                                          </h3>
                                          {communities?.map(
                                            (community, index) => (
                                              <div className="mb-2 w-[48%] inline-block mx-[1%]">
                                                <div
                                                  className="py-2 px-1 flex flex-col cursor-pointer bg-[#fff] rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] lg:mb-0 sm:h-full hover:bg-gray-300 transition-all duration-400"
                                                  key={index}
                                                >
                                                  <div className="flex justify-between items-center">
                                                    <Link
                                                      to={`/community/${community.community_handle}`}
                                                      //target="blank"
                                                    >
                                                      <div className="flex justify-between items-center">
                                                        <img
                                                          src={community?.hero_img?community?.hero_img:"/assets/girl.png"}
                                                          alt="savage-logo"
                                                          className="w-12 h-12  rounded-full border-blue border-[5px] cursor-pointer"
                                                        />
                                                        <div className="lg:w-[80%] pl-2">
                                                          <h2 className=" cursor-pointer ">
                                                            {community?.title}
                                                          </h2>
                                                        </div>
                                                      </div>
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                          <h4 className="text-lg font-semibold mb-2 w-full">
                                            {posts?.length > 0 ? "Post" : null}
                                          </h4>
                                          {posts?.length > 0 &&
                                            posts?.map((posts, index) => (
                                              <div
                                                className="px-3 py-2  flex-col cursor-pointer bg-[#fff] rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] mb-2  sm:h-full hover:bg-gray-300 transition-all duration-400 w-full  inline-block mx-[1%]"
                                                key={index}
                                              >
                                                <Link
                                                  to={`/community/${posts.community.community_handle}/posts/${posts._id}`}
                                                 // target="blank"
                                                >
                                                  <div>
                                                    <h2>{posts.title}</h2>
                                                    <p className="text-[#000]">
                                                      <span className="text-xs text-gray-500 font-semibold line-clamp-2">
                                                        {posts.content}
                                                      </span>
                                                    </p>
                                                  </div>
                                                </Link>
                                              </div>
                                            ))}
                                          <h4 className="text-lg font-semibold my-2">
                                            {users?.length > 0 ? "Users" : null}
                                          </h4>
                                          {users &&
                                            users?.map((user, index) => (
                                              <div
                                                className="px-3 py-2 flex-col cursor-pointer bg-[#fff] rounded-lg shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] mb-2 sm:h-full hover:bg-gray-300 transition-all duration-400 w-[48%]  inline-block mx-[1%]"
                                                key={index}
                                              >
                                                <Link
                                                  to={`/profile/${user._id}`}
                                                  //target="blank"
                                                >
                                                  <div className="">
                                                    <h2 className="w-full flex justify-start items-center">
                                                    <img
                                                          src={user?.hero_img?user?.hero_img:"profile.png"}
                                                          alt="savage-logo"
                                                          className="w-12 h-12  rounded-full border-blue border-[5px] cursor-pointer"
                                                        />
                                                        <p className="pl-1">
                                                      {user.name}
                                                      <span className="text-xs text-gray-500 font-semibold line-clamp-2">
                                                        {user.userHandle}
                                                      </span>
                                                      </p>
                                                    </h2>
                                                   
                                                  </div>
                                                </Link>
                                              </div>
                                            ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex justify-center items-center  overflow-y-auto fixed inset-0 z-50 outline-none  focus:outline-none bg-[#000000b5] ">
                                    <div className="absolute top-14 my-6 mx-auto w-11/12 sm:w-2/5 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-fit overflow-y-scroll">
                                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        <p className="sm:text-2xl text-xl font-semibold my-6 text-center">
                                          No found data
                                        </p>
                                        <button
                                          className="bg-transparent border-0 text-black text-2xl absolute top-1 right-3"
                                          onClick={() => setSearchData(false)}
                                        >
                                          <span>x</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <li className="flex justify-start items-center text-black sm:justify-end mb-1 sm:w-1/4 w-full">
                          <Link to="/profile">
                            <div className="flex items-center justify-center font-semibold rounded-md w-full">
                              <div className="w-full">
                                <img
                                  src={decodedToken.user.hero_img?decodedToken.user.hero_img:userData?.hero_img?userData?.hero_img:"/profile.png"}
                                  alt="savage-logo"
                                  className="w-[50px] rounded-full border-2 border-[#005AE6] cursor-pointer "
                                />
                              </div>
                              <p className="pl-3 text-lg">
                                <span className="block font-semibold capitalize">
                                {decodedToken.user.firstname?decodedToken.user.firstname:userData?.firstname?userData?.firstname:""}
                                  
                                </span>
                              </p>
                            </div>
                          </Link>
                        </li>
                      </>
                    )}
                    {/* <li className={navbar ? 'block absolute top-0 right-4 ' : 'hidden'}>
                      <span className="font-semibold text-lg text-[#005AE6]">
                        Hi {userData?.firstname}
                      </span>
                    </li> */}
                  </div>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`fixed top-0 right-0 mt-2 w-64 z-10 transition-transform duration-700 ease-in-out ${
          isNotification ? "translate-x-0" : "translate-x-full "
        }`}
      >
        {isNotification ? (
          <Notification setIsNotification={setIsNotification} />
        ) : null}
      </div>
    </div>
  );
}
