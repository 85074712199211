import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "./header";
import axios from "axios";
import { apiurl } from "../config/config";
import LoaderSpiner from "./loader";
import { toast } from "react-toastify";
import { getCookie } from "../config/coockies";
import { useUser } from "../contexts/UserProvider";

const MemberList = () => {
  const { cid, caid } = useParams();
  const [getMember, setGetMember] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = getCookie("savage_token");
  const { userData } = useUser();

  const handleGetMemberList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiurl}/user/communities-user/${cid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setGetMember(response.data.data || []);
        setLoading(false);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      console.error("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMemberList();
  }, [cid]);
  const [isFollow, setIsFollow] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const handleSendFollowRequest = async (receiverId,usertype) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiurl}/follow/send-request`,
        { senderId: userData._id, receiverId: receiverId,userType:usertype },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('send follow request response >>>>>>>', response)
      if (response.data.success === true) {
        if(response.data.friendRequest)
          {
            setIsRequest(true);
            setIsFollow(false);
          }
          else{
            setIsRequest(false);
            setIsFollow(true);
          }
          
        setLoading(false);
        toast.success(response.data.message);
      } else {
        setLoading(false);
        console.error(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("send follow request", error);
    }
  };

  const isUser = caid === userData?._id;

  const handleRemoveCommunity = async (memberId) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${apiurl}/community/remove-community/${cid}/member/${memberId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      if (response.data.success === true) {
        setLoading(false);
        handleGetMemberList();
        toast.success(response?.data?.message);
      } else {
        setLoading(false);
        console.error(response?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("remove-community", error);
    }
  };

  return (
    <>
      {loading && <LoaderSpiner text="Loading ..." />}
      <Header />
      <div className="h-screen dark:bg-gray-700 bg-gray-200 p-5">
        <div className="mb-5 bg-cover bg-no-repeat bg-center  bg-[#005AE6]  sm:h-[220px] h-40 rounded-md homepageleft ">
          <div className="homepageright w-full  flex flex-col items-center justify-center  h-full ">
            <h1 className="lg:text-[48px] text-[20px] font-[700] text-[#fff] text-center">
              All members within this community
            </h1>
          </div>
        </div>

        {getMember &&
          getMember.map((member, index) => {
            const isMember = member._id === userData._id;
            return (
              <div className="sm:mx-10 mx-0 mb-2" key={index}>
                <div className="sm:p-5 py-3 flex items-center bg-white justify-between border-t cursor-pointer hover:bg-gray-400 transition-all duration-500 rounded-lg">
                  <Link to={`/profile/${member._id}`}>
                    <div className="flex items-center w-full sm:w-20 sm:h-16 ml-4 sm:ml-0 py-3">
                      <img
                        className="rounded-full h-20 w-20"
                        src={member.hero_img}
                        alt="member image"
                      />
                    </div>
                  </Link>
                  <div className="flex sm:flex-row sm:justify-between sm:items-center w-1/2 sm:w-full flex-col items-start ml-5">
                    <Link to={`/profile/${member._id}`}>
                      <div className="ml-2 flex flex-col">
                        <div className="leading-snug sm:text-lg text-sm text-gray-900 font-semibold mb-1">
                          {member.name}
                        </div>
                        <div className="leading-snug sm:text-sm text-xs text-gray-600">
                          {member.userHandle}
                        </div>
                        <div className="leading-snug sm:text-sm text-xs text-gray-600">
                          {member.location}
                        </div>
                      </div>
                    </Link>
                    <div className="flex justify-between items-center sm:mt-0 mt-2">
                      
                        <button
                          className="sm:py-2 py-1 px-6  sm:text-md text-sm sm:font-semibold font-normal bg-red-100 mr-2 text-red-500 border border-red-600 rounded-full  hover:bg-red-600 hover:text-white transition-all duration-300"
                          onClick={() => handleRemoveCommunity(member._id)}
                        >
                          Remove
                        </button>
                      
                      {/*{!isMember && (
                        <button
                          className="sm:py-2 py-1 px-8 sm:text-md text-sm font-semibold text-blue-500 border border-blue-600 rounded-full  hover:bg-blue-600 hover:text-white transition-all duration-300"
                          onClick={() => handleSendFollowRequest(member._id,member.userType)}
                        >
                        
                          Follow
                        </button>
                      )}*/}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {getMember && getMember.length === 0 && (
          <div className="flex justify-center items-center mb-10">
            <p className="sm:text-3xl text-xl font-semibold">
              No members are available
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default MemberList;
