import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../contexts/UserProvider";
import { TiEye } from "react-icons/ti";
import { Link } from "react-router-dom";
import LoaderSpiner from "./loader";

const Orders = () => {
  const { userData } = useUser();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGetOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://marketplace-api.savageseller.com/order/getOrderByCustomerEmail/${userData.email}`
      );
      console.log(
        "order response -------------------------------->>>>>>>",
        response
      );
      if (response.data.success === true) {
        setLoading(false);
        setOrders(response.data?.response || []);
      } else {
        setLoading(false);
        console.log("order error");
      }
    } catch (error) {
      setLoading(false);
      console.error("order error", error);
    }
  };

  useEffect(() => {
    handleGetOrders();
  }, [userData.email]);

  return (
    <>
      {loading === true ? <LoaderSpiner text="Loading ..." /> : null}
      <div className="p-4 bg-gray-200">
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5 ">
            <div className="py-2 inline-block min-w-full ">
              <div className="overflow-hidden rounded-lg">
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead className="bg-white border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4"
                        >
                          Serial no.
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4"
                        >
                          Order ID
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4"
                        >
                          Order Title
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4"
                        >
                          Customer Name
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4"
                        >
                          Total
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4"
                        >
                          Payment Status
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4"
                        >
                          View Order
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.length > 0 ? (
                        orders.map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b hover:bg-slate-100 transition-all duration-500"
                          >
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">
                              {index + 1}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-light text-center">
                              {item.id}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-light text-center">
                              {item.line_items[0]?.name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-light text-center">
                              {item.total_price}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-light text-center">
                              {item.customer?.first_name}{" "}
                              {item.customer?.last_name}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-light text-center">
                              {item.line_items?.length}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-light text-center">
                              {item.payment_gateway_names[0]}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-light text-center">
                              <Link to={item.order_status_url}>
                                <button>
                                  <TiEye className="text-2xl text-blue-700" />
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8" className="px-6 py-10 text-center">
                            <p className="text-xl font-semibold">
                              No orders available
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;
