import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Layout from "../components/layout";
import TopBanner from "../components/topBanner";
import { CiEdit, CiBookmarkRemove } from "react-icons/ci";
import { useUser } from "../contexts/UserProvider";
import { getCookie } from "../config/coockies";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { apiurl, marketplaceapiurl } from "../config/config";
import { MdVerified } from "react-icons/md";
const MySwal = withReactContent(Swal);

const Communities = () => {
  const token = getCookie("savage_token");
  const { userData } = useUser();
  const [loading, setLoading] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [searchCommunities, setSearchCommunities] = useState("");
  const navigate = useNavigate();

  const fetchCommunities = () => {
    setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/community/get`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setCommunities(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log('all community data', communities)

  useEffect(() => {
    fetchCommunities();
  }, []);

  const handleSearchCommunities = async () => {
    // console.log(searchCommunities);
    if (searchCommunities.length > 0) {
      try {
        const response = await axios.get(
          `${apiurl}/community/searchcommunity?by=${searchCommunities}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
          }
        );
        // console.log('Search communities response  >>>>>>>>>>>>>>>>>>-----:', response.data);
        setCommunities(response.data.data);
      } catch (error) {
        console.error("Error searching communities:", error);
      }
    } else {
      fetchCommunities();
    }
  };

  const deleteCommunityPop = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteCommunity(id);
        MySwal.fire({
          title: "Deleted!",
          text: "Your Community has been deleted.",
          icon: "success",
        });
      }
    });
  };

  const deleteCommunity = async (id) => {
    // console.log(searchCommunities);
    try {
      const response = await axios.delete(
        `${apiurl}/community/delete-community/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );
      // console.log('Search communities response  >>>>>>>>>>>>>>>>>>-----:', response.data);
      fetchCommunities();
    } catch (error) {
      console.error("Error searching communities:", error);
    }
  };
  const[sellerType,setSellerType]=useState(false)
  const [vendorStatus, setVendorStatus] = useState({});
  const checkVendor = async (email) => {
    try {
      const { data } = await axios.post(
        `${marketplaceapiurl}/seller/checksellerexistance`,
        { email }
      );
      return data?.data?.sellerType === "organization";
    } catch (error) {
      console.error("Error checking vendor status:", error);
      return false;
    }
  };
  useEffect(() => {
    const fetchVendorStatus = async () => {
      const statuses = {};
      for (const community of communities) {
        const isVendor = await checkVendor(community?.author?.useremail);
        statuses[community._id] = isVendor;
      }
      setVendorStatus(statuses);
    };

    if (communities.length > 0) {
      fetchVendorStatus();
    }
  }, [communities]);
console.log(sellerType)
  return (
    <Layout>
      <div className="bg-[#F9F9F9] p-5">
        <TopBanner text="Communities" />
        {loading === true ? (
          <div className="w-3/4 m-auto p-5">
            <div className="grid grid-cols-3 gap-4">
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>

              {/* Second Row */}
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>
              <div className="bg-gray-200 rounded p-4 animate-pulse p-20"></div>
            </div>
          </div>
        ) : (
          <div className="pt-5">
          
            <div className="sm:flex block">
            <div className=" flex bg-[#fff] lg:w-1/3 w-full m-auto rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
              <button className="px-4 focus:outline-none">
                <FaSearch className="h-5 w-5 text-[#000]" />
              </button>
              <input
                type="text"
                className="py-2 lg:w-[85%] w-[80%]  focus:outline-none"
                value={searchCommunities}
                placeholder="Search Communities"
                onChange={(e) => setSearchCommunities(e.target.value)}
                onKeyUp={handleSearchCommunities}
              />
              
            </div>
            <div
              className="sm:mt-0 mt-2 bg-blue-800 hover:bg-blue-600 text-white text-center py-1 px-3 rounded-md transition duration-300  font-semibold text-sm lg:text-lg border border-[#fff] cursor-pointer"
              onClick={() => navigate("/create-community")}
            >
              Create a Community
            </div>
            <div
            className="sm:ml-5 ml-0 sm:mt-0 mt-2 bg-blue-800 hover:bg-blue-600 text-white text-center py-1 px-3 rounded-md transition duration-300  font-semibold text-sm lg:text-lg border border-[#fff] cursor-pointer"
            onClick={() => navigate("/profilecommunity")}
          >
            Your communities
          </div>
          </div>

            <div className="lg:grid  block grid-cols-4 gap-4 w-full m-auto my-5">
              {communities &&
                communities.map((item, index) => {
                  const isUser = item.author?._id === userData?._id || null;
                  const isVendor = vendorStatus[item._id] || false;
                  return (
                    <div
                      className="p-4 flex flex-col bg-[#fff] rounded-md shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] lg:mb-0 mb-5 sm:h-full"
                      key={index}
                    >
                      <Link to={`/community/${item.community_handle}`}>
                        <div className="flex justify-between mb-2 items-center  bg-cover bg-no-repeat bg-[#302b27] bg-[url('/public/assets/blue-bg.png')] rounded-md h-[80px]"></div>
                        <div className="flex justify-between mb-2 items-center  ">
                          <img
                            src={item.hero_img}
                            alt="profile image"
                            className="w-[60px] h-[60px]  rounded-full border-blue border-[5px] cursor-pointer mt-[-50px] ml-5"
                          />
                          {item.communityType === "public" ? (
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500/10">
                              Public
                            </span>
                          ) : item.communityType === "private" ? (
                            <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-600 ring-1 ring-inset ring-red-500/10">
                              Private
                            </span>
                          ) : item.communityType === "protected" ? (
                            <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500/10">
                              Protected
                            </span>
                          ) : null}
                        </div>

                        <div className="lg:w-full relative pl-2 border-b border-[#f0f0f0] pb-5">
                          <h2 className="flex items-center text-[16px] lg:text-[20px] font-bold  cursor-pointer">
                            {isVendor ?<MdVerified className="text-blue-600 mr-2"/>:""}{item.title}
                          </h2>
                          <p className="font-[400] text-[14px] text-[#000] line-clamp-3">
                            {item.about}
                          </p>
                        </div>
                      </Link>
                      <div className="lg:w-full relative pl-2 mt-5 justify-between items-center flex">
                        <p className="text-[rgb(0,0,0)] flex absolute bottom-8">
                          {isUser === true && (
                            <>
                              <CiEdit
                                onClick={() =>
                                  navigate(
                                    `/create-community?handle=${item.community_handle}&cid=${item._id}`
                                  )
                                }
                                className="text-[#fff] bg-[#038703] border mr-1 border-gray-500 rounded-full p-1.5 h-8 w-8 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                              />
                              <CiBookmarkRemove
                                onClick={() => deleteCommunityPop(item._id)}
                                className="text-[#fff] bg-[#ff0000]  rounded-full p-1.5 h-8 w-8 hover:bg-blue-700 transition-all duration-300 hover:text-white cursor-pointer"
                              />
                            </>
                          )}
                        </p>
                        <span className="text-sm capitalize mt-4 font-semibold">
                          {item?.author?.username}
                        </span>

                        <div>
                          <p className="flex items-center  text-[#9e9e9e]">
                            <img
                              src="/assets/member1.jpg"
                              alt="savage-member"
                              className="w-8 h-8 rounded-[100%] border-[#f0f0f0] border-[2px] "
                            />
                            <img
                              src="/assets/member2.jpg"
                              alt="savage-member"
                              className="w-8 h-8 rounded-[100%] border-[#f0f0f0] border-[2px] ml-[-10px]"
                            />
                            <img
                              src="/assets/member3.jpg"
                              alt="savage-member"
                              className="w-8 h-8 rounded-[100%] border-[#f0f0f0] border-[2px] ml-[-10px]"
                            />
                          </p>
                          <span className="mx-2 text-xs">
                            {
                              item?.members.filter(
                                (member) => member.status === "approved"
                              ).length
                            }{" "}
                            Member
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Communities;
